import { Form } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { ReportFieldSelectorCard } from "./ReportFieldSelectorCard";
import ChartTypeSelector from "../atoms/ChartTypeSelector";

import {
  SurveyReport,
  SurveyReportField,
} from "../../entities/survey-reports/SurveyReport";
import { ChartConfig, ChartKey } from "../../entities/chart/ChartConfig";
import { ChartType } from "../../entities/chart/ChartType";
import { IReportFieldProvider } from "../../entities/survey-reports/ReportFields";
import ChartGroupSelector from "../atoms/ChartGroupSelector";
import {
  ChartGroupKey,
  IChartGroupProvider,
} from "../../entities/chart/ChartGroup";

type Props = {
  onChartCreated: (
    groupKey: string,
    config: Omit<ChartConfig, "dateRange" | "title">
  ) => void;
  onUpdated: (config: Omit<ChartConfig, "dateRange" | "key" | "title">) => void;
  createDisabled?: boolean;
  existingReports?: readonly SurveyReport[];
  reportFieldProvider: IReportFieldProvider;
  chartGroupProvider: IChartGroupProvider;
};

export default function ChartBuilderCard(props: Props) {
  const [selectedField, setSelectedField] =
    useState<SurveyReportField>("reportType");
  const [selectedChartType, setSelectedChartType] =
    useState<ChartType>("bar-horizontal");
  const [selectedChartGroup, setSelectedChartGroup] = useState<
    ChartGroupKey | undefined
  >();

  useEffect(() => {
    if (
      props.chartGroupProvider.chartGroupKeysWithLabel.find(([key]) => {
        return key === selectedChartGroup;
      }) === undefined
    ) {
      setSelectedChartGroup(
        props.chartGroupProvider.chartGroupKeysWithLabel[0][0]
      );
    }
  }, [selectedChartGroup, props.chartGroupProvider.chartGroupKeysWithLabel]);

  useEffect(() => {
    props.onUpdated({
      type: selectedChartType,
      reportField: selectedField,
    });
  }, [props.onUpdated, selectedChartType, selectedField]);

  const createClicked = useCallback(() => {
    props.onChartCreated(
      selectedChartGroup ??
        props.chartGroupProvider.chartGroupKeysWithLabel[0][0],
      {
        key: uuidv4() as ChartKey,
        type: selectedChartType,
        reportField: selectedField,
      }
    );
  }, [
    props.onChartCreated,
    selectedChartType,
    selectedChartGroup,
    selectedField,
  ]);

  return (
    <Form layout="vertical">
      <ReportFieldSelectorCard
        type="single"
        separateBocFields
        skipNonQualitative
        title="Chart Builder"
        value={selectedField}
        onFieldsChanged={setSelectedField}
        createClicked={createClicked}
        createDisabled={props.createDisabled}
        provider={props.reportFieldProvider}
      >
        <ChartTypeSelector
          value={selectedChartType}
          setChartType={setSelectedChartType}
        />
        <ChartGroupSelector
          value={
            selectedChartGroup ??
            props.chartGroupProvider.chartGroupKeysWithLabel[0][0]
          }
          setChartGroup={setSelectedChartGroup}
          groups={props.chartGroupProvider.chartGroupKeysWithLabel}
        />
      </ReportFieldSelectorCard>
    </Form>
  );
}
