import { Layout, Menu, Typography, theme } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import FabdacsLogo from "../atoms/FabdacsLogo";
import Sider from "antd/es/layout/Sider";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  errorMessage?: string;
};

const LoggedOutPage = (props: Props) => {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const {
    token: { colorBgLayout, colorBgContainer, controlHeight, margin },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);

  const year = new Date().getFullYear();

  return (
    <Layout style={{ backgroundColor: colorBgLayout, minHeight: "100vh" }}>
      <Header style={{ backgroundColor: colorBgContainer, padding: "0 20px" }}>
        <FabdacsLogo height={`${controlHeight}px`} />
      </Header>
      <Layout>
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
        >
          <Menu
            theme="light"
            mode="inline"
            style={{
              height: "100%",
            }}
          />
        </Sider>
        <Layout>
          <Layout
            style={{
              margin: `0 ${margin}px`,
              height: "100%",
            }}
          >
            <Content
              style={{
                minHeight: 360,
              }}
            >
              <Typography.Title>
                {props.errorMessage ? props.errorMessage : "Loading...."}
              </Typography.Title>
            </Content>
            <Footer style={{ textAlign: "center" }}>FABDACS ©{year}</Footer>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LoggedOutPage;
