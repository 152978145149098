import * as antdColor from "@ant-design/colors";
import { ChartData, ChartDataset, ScriptableContext } from "chart.js";

import { localeCompareSort } from "../utils/sorters";
import { ReportFieldDefinition } from "../entities/survey-reports/ReportFields";

function generateColorVariations(
  baseColor: string,
  numVariations: number
): string[] {
  const colorInt = parseInt(baseColor.slice(1), 16);
  const red = (colorInt >> 16) & 255;
  const green = (colorInt >> 8) & 255;
  const blue = colorInt & 255;

  const variations = [];
  for (let i = 1; i <= numVariations; i++) {
    const intensity = i / (numVariations + 1);
    const newRed = Math.round(red * intensity);
    const newGreen = Math.round(green * intensity);
    const newBlue = Math.round(blue * intensity);
    const newColorInt = (newRed << 16) | (newGreen << 8) | newBlue;
    const newColorHex = "#" + newColorInt.toString(16).padStart(6, "0");
    variations.push(newColorHex);
  }

  return variations;
}

function hexColorsToCanvasGradient(
  colors: string[],
  direction: "vertical" | "horizontal" = "horizontal",
  chartContext: ScriptableContext<"line">
): CanvasGradient | undefined {
  const { ctx, chartArea, scales } = chartContext.chart;
  const { x: xAxis, y: yAxis } = scales;
  if (!chartArea) {
    return undefined;
  }

  const gradient =
    direction === "horizontal"
      ? ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0)
      : ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

  colors.forEach((color, i) => {
    let offset: number;
    if (direction === "horizontal") {
      const x = xAxis.getPixelForValue(i) - chartArea.left;
      offset = (1 / chartArea.width) * x;
    } else {
      const y = yAxis.getPixelForValue(i) - chartArea.top;
      offset = (1 / chartArea.height) * y;
    }

    gradient.addColorStop(offset, color);
  });

  return gradient;
}

function uniqueKeysForNestedObject<T extends string>(
  obj: Record<string, Record<T, unknown>>
): T[] {
  const values = Object.values(obj).flatMap((o) => Object.keys<T>(o));
  // remove duplicates
  return [...new Set(values)];
}

export function getChartDataSingleFromReportField<
  XValue extends string,
  YValue extends string | number,
  ChartType extends "bar" | "pie" | "line"
>(
  data: Array<[XValue, YValue]>,
  type: ChartType,
  reportFieldDef: ReportFieldDefinition
) {
  let explanatoryAxisSettings: AxisSettings<XValue>;
  let responseAxisSettings: AxisSettings<YValue>;

  if (type === "line") {
    explanatoryAxisSettings = {};
    responseAxisSettings = {
      // @ts-ignore
      sortValues: reportFieldDef.sortValues,
      // @ts-ignore
      valueToLabel: reportFieldDef.valueToLabel,
      // @ts-ignore
      valueToColor: reportFieldDef.valueToColor,
    };
  } else {
    explanatoryAxisSettings = {
      // @ts-ignore
      sortValues: reportFieldDef.sortValues,
      // @ts-ignore
      valueToLabel: reportFieldDef.valueToLabel,
      // @ts-ignore
      valueToColor: reportFieldDef.valueToColor,
    };
    responseAxisSettings = {};
  }

  return getChartDataSingle(
    data,
    type,
    explanatoryAxisSettings,
    responseAxisSettings
  );
}

// Axis Settings
type AxisSettings<Value extends string | number> = {
  sortValues?: (a: Value, b: Value) => number;
  valueToLabel?: (value: Value) => string;
  valueToColor?: (value: Value) => string;
};

export function getChartDataSingle<
  XValue extends string | number,
  YValue extends string | number,
  ChartType extends "bar" | "pie" | "line"
>(
  data: Array<[XValue, YValue]>,
  type: ChartType,

  explanatoryAxisSettings: AxisSettings<XValue> = {},
  responseAxisSettings: AxisSettings<YValue> = {}
): ChartData<ChartType, YValue[], string> {
  const sortedValues = data.sort(([x0], [x1]) =>
    (explanatoryAxisSettings.sortValues ?? localeCompareSort)(x0, x1)
  );

  const sortedXValues = sortedValues.map(([x]) => x);
  const sortedYValues = sortedValues.map(([_, y]) => y);

  let chartColors: string[];
  if (type === "line") {
    chartColors =
      responseAxisSettings.valueToColor !== undefined
        ? sortedYValues.map(responseAxisSettings.valueToColor)
        : generateColorVariations("#a3ff93", sortedYValues.length);
  } else {
    chartColors =
      explanatoryAxisSettings.valueToColor !== undefined
        ? sortedXValues.map(explanatoryAxisSettings.valueToColor)
        : generateColorVariations("#a3ff93", sortedXValues.length);
  }

  const dataSet = {
    type,
    data: sortedYValues,
  } as ChartDataset<ChartType, YValue[]>;

  const chartData = {
    datasets: [dataSet],
    labels: explanatoryAxisSettings.valueToLabel
      ? sortedXValues.map(explanatoryAxisSettings.valueToLabel)
      : sortedXValues,
  } as ChartData<ChartType, YValue[], string>;

  if (type === "line") {
    dataSet.borderColor = antdColor.grey.primary!;
    dataSet.backgroundColor = (ctx: ScriptableContext<"line">) =>
      hexColorsToCanvasGradient(chartColors, "horizontal", ctx);
    dataSet.borderColor = (ctx: ScriptableContext<"line">) =>
      hexColorsToCanvasGradient(chartColors, "horizontal", ctx);
  } else {
    dataSet.backgroundColor = chartColors;
  }
  // dataSet.label = labelForKey ? labelForKey();

  return chartData;
}

export function getChartDataMultipleFromReportField<
  ChartType extends "bar" | "line"
>(
  data: Array<[string, Record<string, number>]>,
  type: ChartType,
  reportFieldDef: ReportFieldDefinition
) {
  return getChartDataMultiple(
    data,
    type,
    // @ts-ignore
    reportFieldDef.valueToLabel,
    // @ts-ignore
    reportFieldDef.sortValues,
    reportFieldDef.valueToColor
  );
}

export function getChartDataMultiple<
  ChartType extends "bar" | "line",
  LabelKey extends string,
  YValue extends string | number
>(
  data: Array<[string, Record<LabelKey, YValue>]>,
  type: ChartType,
  labelForKey: undefined | ((value: LabelKey) => string) = undefined,
  sortKeys: undefined | ((a: LabelKey, b: LabelKey) => number) = undefined,
  colorForValue: undefined | ((value: LabelKey) => string) = undefined
): ChartData<ChartType, YValue[], string> {
  const dateKeys = data.map(([date]) => date);

  const sortedLabelKeys = data
    .map(([_, values]) => Object.keys(values))
    .flat()
    .sort(sortKeys ?? localeCompareSort);
  const uniqueLabelKeys = [...new Set(sortedLabelKeys)];

  const chartColors = generateColorVariations(
    "#a3ff93",
    uniqueLabelKeys.length
  );

  const datasets = uniqueLabelKeys.map((labelKey, i) => {
    const values = data.map(([_, d]) => d[labelKey]);

    const color = colorForValue ? colorForValue(labelKey) : chartColors[i];
    const dataSet = {
      type,
      data: values,
    } as ChartDataset<ChartType, YValue[]>;

    if (type === "line") {
      dataSet.borderColor = antdColor.grey.primary!;
      dataSet.backgroundColor = (ctx: ScriptableContext<"line">) =>
        hexColorsToCanvasGradient(chartColors, "horizontal", ctx);
    } else {
      dataSet.backgroundColor = color;
    }
    dataSet.label = labelForKey ? labelForKey(labelKey) : labelKey;

    return dataSet;
  });

  return { datasets, labels: dateKeys };
}
