import { DateTime, DateTimeUnit } from "luxon";
import { DateRange } from "./dateRange";

export default function dateTimeUnitRange(
  range: DateRange,
  unit: DateTimeUnit
): Array<DateTime> {
  if (range.end.diff(range.start).valueOf() <= 0) {
    throw new RangeError(
      "Could not create dateTimeUnitRange list, endDate was equal to or before startDate"
    );
  }

  const dates: Array<DateTime> = [];

  // The provided start & end date
  let currentDate = range.start.startOf(unit);
  const limitDate = range.end.endOf(unit);

  do {
    dates.push(currentDate);
    currentDate = currentDate.plus({ [unit]: 1 });
  } while (currentDate.diff(limitDate).valueOf() <= 0);

  return dates;
}
