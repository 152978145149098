import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Alert, Flex, Layout, Menu, MenuProps, theme } from "antd";
import {
  UsergroupAddOutlined,
  FileSearchOutlined,
  LogoutOutlined,
  LineChartOutlined,
  FileTextOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";

import { FabdacsPage, PageStateContext } from "../../FabdacsApp";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0FabdacsRoles } from "../../api/auth0";
import FabdacsLogo from "../atoms/FabdacsLogo";
import FreshdeskWidget from "../atoms/FreshdeskWidget";

const { Header, Content, Footer } = Layout;

type Props = {
  bgColor?: string;
  breadcrumbs?: string[];
  hidePractitionerMenuItems?: boolean;
};

type MenuItem = Required<MenuProps>["items"][number];

function getMenuItem(
  key: string,
  label: string,
  onClick: () => void,
  icon?: React.ReactNode,
  items?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    onClick,
    items,
    label,
  } as MenuItem;
}

const getItems = (
  roles: Auth0FabdacsRoles[],
  hidePractitionerMenuItems: boolean,
  onClick: (state: FabdacsPage) => void,
  onLogout: () => void
): MenuItem[] => {
  const allItems = [
    getMenuItem("logout", "Logout", () => onLogout(), <LogoutOutlined />),
  ];

  const adminItems = [
    getMenuItem(
      "data-import",
      "Data Import",
      () => onClick("data-import"),
      <UsergroupAddOutlined />
    ),
  ];

  const practitionerItems = hidePractitionerMenuItems
    ? []
    : [
        getMenuItem(
          "overview",
          "Overview",
          () => onClick("overview"),
          <HomeOutlined />
        ),
        getMenuItem(
          "star-filter",
          "Star Filter",
          () => onClick("star-filter"),
          <FileSearchOutlined />
        ),
        getMenuItem(
          "chart-builder",
          "Chart Builder",
          () => onClick("chart-builder"),
          <LineChartOutlined />
        ),
        getMenuItem(
          "report-builder",
          "Report Builder",
          () => onClick("report-builder"),
          <FileTextOutlined />
        ),
      ];

  if (roles.includes("admin")) {
    return [...adminItems, ...practitionerItems, ...allItems];
  } else if (roles.includes("practitioner")) {
    return [...practitionerItems, ...allItems];
  } else {
    return [];
  }
};

const AsyncErrorCatcher = (props: { children: React.ReactNode }) => {
  const [_, setError] = useState<Error | undefined>(undefined);

  const handleRejectedPromise = useCallback(
    (e: PromiseRejectionEvent) => {
      console.error(e.reason);
      setError(() => {
        throw e.reason ?? e;
      });
    },
    [setError]
  );

  useEffect(() => {
    window.addEventListener("unhandledrejection", handleRejectedPromise);
    return () => {
      window.removeEventListener("unhandledrejection", handleRejectedPromise);
    };
  });

  return <Alert.ErrorBoundary>{props.children}</Alert.ErrorBoundary>;
};

const LoggedInTemplate = (props: PropsWithChildren<Props>) => {
  const { pageState, setPageState } = useContext(PageStateContext);
  const { user, logout } = useAuth0();

  const roles = user?.["https://app-preview.fabdacs.co/roles"];

  const changePage = useCallback(
    (page: FabdacsPage) => {
      // @ts-ignore
      setPageState({ ...pageState, page });
    },
    [setPageState]
  );

  const {
    token: { colorBgLayout, colorBgContainer, controlHeight, margin },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);

  const menuItems = useMemo(
    () =>
      getItems(
        roles,
        props.hidePractitionerMenuItems ?? false,
        changePage,
        () => logout()
      ),
    [roles, props.hidePractitionerMenuItems, changePage, logout]
  );

  const year = new Date().getFullYear();

  return (
    <Layout style={{ backgroundColor: colorBgLayout, minHeight: "100vh" }}>
      <Header style={{ backgroundColor: colorBgContainer, padding: "0 20px" }}>
        <Flex align="center" justify="space-between">
          <FabdacsLogo height={`${controlHeight}px`} />
          <FreshdeskWidget />
        </Flex>
      </Header>
      <Layout>
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
        >
          <Menu
            theme="light"
            selectedKeys={[pageState.page]}
            mode="inline"
            items={menuItems}
            style={{
              height: "100%",
            }}
          />
        </Sider>
        <Layout>
          <Layout
            style={{
              margin: `${margin}px`,
              height: "100%",
            }}
          >
            {/* <Breadcrumb
              style={{ margin: `${margin}px 0 0` }}
              items={props.breadcrumbs?.map((b) => ({
                title: b,
              }))}
            /> */}
            <Content
              style={{
                minHeight: 360,
              }}
            >
              <Alert.ErrorBoundary>
                <AsyncErrorCatcher>{props.children}</AsyncErrorCatcher>
              </Alert.ErrorBoundary>
            </Content>
            <Footer style={{ textAlign: "center" }}>FABDACS ©{year}</Footer>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LoggedInTemplate;
