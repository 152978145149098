import { Card, Input } from "antd";

type Props = {
  value?: string;
  onUpdate: (value: string) => void;
  placeholder: string;
};

const InputCard = (props: Props) => {
  return (
    <Card size="small">
      <Input.TextArea
        placeholder={props.placeholder}
        bordered={false}
        allowClear
        value={props.value}
        onChange={(e) => props.onUpdate(e.target.value)}
        style={{ resize: "none" }}
        rows={props.value?.split("\n").length ?? 1}
      />
    </Card>
  );
};

export default InputCard;
