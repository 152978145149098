import { ReactElement, useMemo } from "react";
import { Form, Select, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { IReportFieldProvider } from "../../entities/survey-reports/ReportFields";
import { SurveyReportField } from "../../entities/survey-reports/SurveyReport";
import { groupBy } from "../../utils/array";

type Props = {
  type: "single" | "multiple";
  separateBocFields?: boolean;
  skipNonQualitative?: boolean;
  disabledOptions?: Array<SurveyReportField>;
  provider: IReportFieldProvider;
} & (
  | {
      type: "single";
      onFieldsChanged: (list: SurveyReportField) => void;
      value: SurveyReportField;
    }
  | {
      type: "multiple";
      onFieldsChanged: (list: SurveyReportField[]) => void;
      value: readonly SurveyReportField[];
    }
);

export default function ReportFieldSelector(props: Props): ReactElement<Props> {
  const options = useMemo(() => {
    const baseFields =
      props.separateBocFields ?? false
        ? props.provider.fields
        : props.provider.fieldsNoBoc;

    const filtered = props.skipNonQualitative
      ? baseFields.filter((f) => f.notQuantitative !== true)
      : baseFields;

    const grouped = groupBy(filtered, (f) => f.group);

    return Object.entries(grouped).map(([group, options]) => {
      return {
        label: group,
        options: options.map((option) => {
          return {
            label: option.label,
            value: option.reportField,
            disabled: props.disabledOptions?.includes(option.reportField),
          } satisfies DefaultOptionType;
        }),
      } as DefaultOptionType;
    });
  }, [
    props.separateBocFields,
    props.skipNonQualitative,
    props.disabledOptions,
  ]);

  return (
    <Form.Item
      label={<Typography.Text strong>Report Field</Typography.Text>}
      style={{ width: "100%", marginBottom: "7px" }}
    >
      <Select
        mode={props.type === "multiple" ? "multiple" : undefined}
        style={{ width: "100%" }}
        onChange={(t) => props.onFieldsChanged(t as any)}
        value={props.value}
        options={options}
      />
    </Form.Item>
  );
}
