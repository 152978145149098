import * as color from "@ant-design/colors";
import { makeAutoObservable } from "mobx";

import { SurveyReportField } from "../entities/survey-reports/SurveyReport";
import {
  IReportField,
  IReportFieldProvider,
  REPORT_FIELD_OPTIONS_BASE,
  ReportFieldDefinition,
} from "../entities/survey-reports/ReportFields";
import {
  IBehaviourOfConcern,
  isBehaviourOfConcernKey,
} from "../entities/behaviour-report/BehaviourOfConcern";
import { ActionSeverity } from "../entities/behaviour-report/ActionSeverity";
import { assertNever } from "../utils/assertNever";

export class ReportFieldStore implements IReportFieldProvider {
  private readonly _fields: ReadonlyArray<ReportFieldDefinition> =
    REPORT_FIELD_OPTIONS_BASE;
  private readonly _bocFields: Array<ReportFieldDefinition> = [];

  constructor(bocs: ReadonlyArray<IBehaviourOfConcern>) {
    makeAutoObservable(this);
    bocs.forEach((b) => this.addBehaviourOfConcernField(b));
  }

  get fields(): ReadonlyArray<ReportFieldDefinition> {
    return [...this._fields, ...this._bocFields];
  }

  get fieldsNoBoc(): ReadonlyArray<ReportFieldDefinition> {
    return this._fields;
  }

  get fieldsOnlyBoc(): ReadonlyArray<ReportFieldDefinition> {
    return this._bocFields;
  }

  getReportFieldOption<K extends SurveyReportField>(reportField: K) {
    if (isBehaviourOfConcernKey(reportField)) {
      return this._bocFields.find((f) => f.reportField === reportField)!;
    }

    return this._fields.find((f) => f.reportField === reportField)!;
  }

  private addBehaviourOfConcernField(boc: IBehaviourOfConcern) {
    this._bocFields.push({
      label: `${boc.label} - Actions`,
      reportField: `${boc.key}.actions`,
      renderType: "tag",
      group: "Behaviours Of Concern",
      notFilterable: true,
    });

    this._bocFields.push({
      label: `${boc.label} - Severities`,
      reportField: `${boc.key}.severity`,
      renderType: "tag",
      group: "Behaviours Of Concern",
      notFilterable: true,
      valueToColor: (value: ActionSeverity | "0") => {
        switch (value) {
          case "0":
          case "1": {
            return color.blue.primary!;
          }
          case "2":
            return color.green.primary!;
          case "3":
            return color.yellow.primary!;
          case "4":
            return color.orange.primary!;
          case "5":
            return color.red.primary!;
          default:
            assertNever(value);
        }
      },
      valueToLabel: (value) => `Severity ${value}`,
    } as IReportField<`${typeof boc.key}.severity`, ActionSeverity>);

    this._bocFields.sort((a, b) => a.reportField.localeCompare(b.reportField));
  }
}
