import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import FabdacsApp from "./FabdacsApp";
import "./index.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

Sentry.init({
  dsn: "https://b028280192894f509f6db393ec935ded@o4505345493958656.ingest.sentry.io/4505345495203840",
  integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],

  tracesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const container = document.getElementById("root")!;
const root = ReactDOM.createRoot(container);

root.render(<FabdacsApp />);
