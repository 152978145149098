import { Button, Col, InputNumber, Row, Segmented, Select } from "antd";
import { DateTime } from "luxon";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { DateRange } from "../../entities/time/dateRange";
import { ClockCircleOutlined, FileTextOutlined } from "@ant-design/icons";

type PeriodUnit = "days" | "weeks" | "months";
type DurationAnchor = "today" | "last-report";

const PeriodUnitSelect = ({
  value,
  onUpdate,
}: {
  value: PeriodUnit;
  onUpdate: (val: PeriodUnit) => void;
}) => (
  <Select
    defaultValue="days"
    style={{ width: "100px" }}
    dropdownStyle={{ width: "100px", color: "red" }}
    popupMatchSelectWidth
    value={value}
    onChange={onUpdate}
  >
    <Select.Option value="days">Days</Select.Option>
    <Select.Option value="weeks">Weeks</Select.Option>
    <Select.Option value="months">Months</Select.Option>
  </Select>
);

export type DateRangeBuilderState = {
  count?: number;
  period: PeriodUnit;

  offset?: {
    count: number;
    period: PeriodUnit;
  };
};

type Props = {
  onUpdate: (range: DateRange) => void;
  onReset: () => void;
  firstReportDate: DateTime;
  lastReportDate: DateTime;
};

export const DateRangeBuilder = ({
  lastReportDate,
  onUpdate,
  onReset,
}: PropsWithChildren<Props>) => {
  const [builderState, setBuilderState] = useState<DateRangeBuilderState>({
    period: "days",
  });

  const [durationAnchor, setDurationAnchor] =
    useState<DurationAnchor>("last-report");

  useEffect(() => {
    if (builderState.count === undefined) {
      return;
    }

    let end = durationAnchor === "today" ? DateTime.now() : lastReportDate;
    if (builderState.offset) {
      end = end.minus({
        [builderState.offset.period]: builderState.offset.count,
      });
    }

    const start = end.minus({ [builderState.period]: builderState.count });
    onUpdate({ start, end });
  }, [lastReportDate.toISO(), durationAnchor, builderState, onUpdate]);

  return (
    <Row gutter={[12, 12]} align="middle">
      <Col lg={12}>
        <InputNumber
          style={{ width: "100%" }}
          addonBefore="Last"
          min={1}
          onChange={(count) =>
            setBuilderState({ ...builderState, count: count! })
          }
          addonAfter={
            <PeriodUnitSelect
              value={builderState.period}
              onUpdate={(unit) =>
                setBuilderState({ ...builderState, period: unit })
              }
            />
          }
          value={builderState.count}
        />
      </Col>
      <Col lg={12}>
        <InputNumber
          addonBefore="-"
          style={{ width: "100%" }}
          onChange={(count) =>
            setBuilderState({
              ...builderState,
              offset: {
                ...builderState.offset,
                count: count!,
                period: builderState.period ?? "days",
              },
            })
          }
          addonAfter={
            <PeriodUnitSelect
              value={builderState.offset?.period ?? "days"}
              onUpdate={(unit) =>
                setBuilderState({
                  ...builderState,
                  offset: {
                    count: builderState.offset?.count ?? 0,
                    period: unit,
                  },
                })
              }
            />
          }
          defaultValue={4}
          value={builderState.offset?.count ?? 0}
        />
      </Col>
      <Col md={12}>
        <Row justify="center">
          <Segmented
            options={[
              {
                label: (
                  <Col>
                    <ClockCircleOutlined />
                    <div>From Today</div>
                  </Col>
                ),
                value: "today",
              },
              {
                label: (
                  <Col>
                    <FileTextOutlined />
                    <div>From Last Report</div>
                  </Col>
                ),
                value: "last-report",
              },
            ]}
            value={durationAnchor}
            onChange={(val) => setDurationAnchor(val as DurationAnchor)}
          />
        </Row>
      </Col>
      <Col md={12}>
        <Button block onClick={onReset}>
          Reset (All Reports)
        </Button>
      </Col>
    </Row>
  );
};
