import { Button, Card, Col, Row, Space } from "antd";
import ReportFieldSelector from "../atoms/ReportFieldSelector";
import { PropsWithChildren, useMemo } from "react";

type Props = {
  title: string;
  createClicked?: () => void;
  createDisabled?: boolean;
  resetClicked?: () => void;
} & ReturnType<typeof ReportFieldSelector>["props"];

export function ReportFieldSelectorCard(props: PropsWithChildren<Props>) {
  const cardActions = useMemo(() => {
    const actions = [];

    if (props.resetClicked) {
      actions.push(
        <Button block type="default" onClick={props.resetClicked}>
          Reset
        </Button>
      );
    }

    if (props.createClicked) {
      actions.push(
        <Button
          block
          onClick={props.createClicked}
          disabled={props.createDisabled}
        >
          Save
        </Button>
      );
    }

    return actions;
  }, [props.resetClicked, props.createClicked, props.createDisabled]);

  return (
    <Card title={props.title} style={{ height: "100%" }}>
      <Space
        direction="vertical"
        size={0}
        style={{ width: "100%", height: "100%" }}
      >
        <ReportFieldSelector {...props} />
        {props.children}
        <Row gutter={[12, 12]} align="middle" justify="center">
          <Col md={12}>{...cardActions}</Col>
        </Row>
      </Space>
    </Card>
  );
}
