import { Button, Col, Flex, Row, Space, Typography } from "antd";
import { usePDF } from "react-to-pdf";
import { observer } from "mobx-react-lite";

import { ParticipantStore } from "../../store/ParticipantStore";
import LoggedInTemplate from "../templates/LoggedInTemplate";
import { useCallback, useMemo, useState } from "react";
import ChartCard from "../organisms/ChartCard";
import { filterReportsByDateRange } from "../../entities/survey-reports/SurveyReport";
import {
  CommonReportMetricsCards,
  reportsToCommonMetrics,
} from "./ParticipantOverview";
import BarChartCard from "../molecules/charts/BarChartCard";
import { DatePicker } from "./ReportsFilterPage";
import {
  DateRangeFromDatePickerValue,
  DateRangeToDatePickerValue,
} from "./ReportsChartPage";
import { disabledDate } from "../molecules/DateCard";
import ChartGroupRenderer from "../organisms/ChartGroupRenderer";
import InputCard from "../atoms/InputCard";
import { dateStringFromLuxon } from "../../entities/time/DateString";
import { DateRange } from "../../entities/time/dateRange";
import { AnnotationsStore } from "../../store/AnnotationsStore";
import { AnnotationKey } from "../../entities/annotation";
import { ChartKey } from "../../entities/chart/ChartConfig";

type Props = {
  participant: ParticipantStore;
};

const QUERY_SELECTORS_TO_HIDE_IN_PDF = [
  ".ant-typography-edit",
  ".ant-input-clear-icon",
];

export function DateRangeHeaderString(dateRange: DateRange) {
  return `${dateStringFromLuxon(dateRange.start)} - ${dateStringFromLuxon(
    dateRange.end
  )}`;
}

const AnnotationInputCard = observer(
  (props: {
    store: AnnotationsStore;
    placeholder: string;
    annotationKey: AnnotationKey;
  }) => {
    const onUpdate = useCallback(
      (v: string) => {
        props.store.updateAnnotation(props.annotationKey, v);
      },
      [props.store, props.annotationKey]
    );

    return (
      <InputCard
        placeholder={props.placeholder}
        value={props.store.getAnnotation(props.annotationKey)}
        onUpdate={onUpdate}
      />
    );
  }
);

const ReportBuilderPage = observer((props: Props) => {
  const [commonDateRange, setCommonDateRange] = useState(
    props.participant.defaultDateRange
  );

  const { toPDF, targetRef } = usePDF();

  const [filteredCommonReports, viewMetrics] = useMemo(() => {
    const filteredReports = filterReportsByDateRange(
      props.participant.reports,
      commonDateRange
    );

    return [
      filteredReports,
      reportsToCommonMetrics(
        filteredReports,
        props.participant.behaviourOfConcernStore.keys
      ),
    ];
  }, [props.participant.reports, commonDateRange]);

  return (
    <LoggedInTemplate
      breadcrumbs={[
        "Participants",
        props.participant.nameReadable,
        "Report Builder",
      ]}
    >
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex" }}
        ref={targetRef}
      >
        <Flex align="end" justify="space-between">
          <Typography.Title level={2}>
            {props.participant.nameReadable} - Report
          </Typography.Title>
          <Button
            size="large"
            onClick={() =>
              toPDF({
                overrides: {
                  canvas: {
                    onclone(document, element) {
                      for (const textarea of document.getElementsByTagName(
                        "textarea"
                      )) {
                        const div = document.createElement("div");
                        div.innerText = textarea.value;
                        // div.style.border = "1px solid #d3d3d3";
                        div.style.padding = "0";
                        div.style.width = "100%";
                        // div.style.borderRadius = "5px"; // crashes screeshot generation
                        div.style.boxSizing = "border-box";
                        div.style.margin = "0";
                        div.style.backgroundColor = "white";
                        div.style.wordBreak = "break-all";
                        textarea.style.display = "none";
                        textarea.parentElement!.appendChild(div);
                      }

                      for (const el of document.querySelectorAll(
                        "div, span, h1, h2, h3, h4, h5, h6"
                      )) {
                        (el as HTMLElement).style.wordBreak = "break-all";

                        if ((el as HTMLElement).innerText === "Print Report") {
                          (el as HTMLElement).style.display = "none";
                        }
                      }

                      for (const selector of QUERY_SELECTORS_TO_HIDE_IN_PDF) {
                        for (const element of document.querySelectorAll(
                          selector
                        )) {
                          (element as HTMLElement).style.display = "none";
                        }
                      }
                    },
                  },
                },
                page: { margin: 10 },
              })
            }
            style={{ paddingBottom: "0.5em" }}
          >
            Print Report
          </Button>
        </Flex>
        <Typography.Title level={3}>Introduction</Typography.Title>
        <AnnotationInputCard
          placeholder="Introduction Placeholder, Click here to Edit"
          annotationKey="introduction"
          store={props.participant.annotationsStore}
        />

        <Row justify="space-between" align="middle" gutter={[6, 6]}>
          <Col>
            <Typography.Title level={3}>Standard Charts</Typography.Title>
          </Col>
          <Col>
            <DatePicker.RangePicker
              disabledDate={(r) =>
                disabledDate(r, props.participant.defaultDateRange)
              }
              value={DateRangeToDatePickerValue(commonDateRange)}
              onCalendarChange={(r) =>
                r && setCommonDateRange(DateRangeFromDatePickerValue(r)!)
              }
            />
          </Col>
        </Row>
        <CommonReportMetricsCards viewMetrics={viewMetrics} />
        <Row justify="space-between" align="middle" gutter={[6, 6]}>
          <Col md={12} lg={6}>
            <ChartCard
              config={{
                dateRange: commonDateRange,
                type: "bar-vertical",
                key: "times-of-behaviour" as ChartKey,
                reportField: "timeOfIncident",
                title: "Times of Behaviour Incidents",
              }}
              provider={props.participant.reportFieldStore}
              reports={filteredCommonReports}
            />
          </Col>
          <Col md={12} lg={6}>
            <ChartCard
              config={{
                dateRange: commonDateRange,
                type: "bar-vertical",
                key: "duration-of-behaviour" as ChartKey,
                reportField: "durationOfIncident",
                title: "Duration of Behaviour Incidents",
              }}
              provider={props.participant.reportFieldStore}
              reports={filteredCommonReports}
            />
          </Col>
          <Col md={12} lg={6}>
            <BarChartCard
              data={viewMetrics.severityChartData}
              reportField={props.participant.reportFieldStore.fieldsOnlyBoc[0]}
              title="Overall Severity of Behaviour"
            />
          </Col>
          <Col md={12} lg={6}>
            <ChartCard
              config={{
                dateRange: commonDateRange,
                type: "bar-vertical",
                key: "function-of-behaviour" as ChartKey,
                reportField: "possibleFunctions",
                title: "Function of Behaviour Incidents",
              }}
              provider={props.participant.reportFieldStore}
              reports={filteredCommonReports}
            />
          </Col>
        </Row>

        <AnnotationInputCard
          placeholder="Placeholder, click here to Edit"
          annotationKey="standardCharts"
          store={props.participant.annotationsStore}
        />

        {...props.participant.chartConfigStore.chartGroups.map((g) => (
          <>
            <Row>
              <Typography.Title
                level={3}
                editable={{
                  onChange: (newText) =>
                    props.participant.chartConfigStore.renameChartGroup(
                      g.key,
                      newText
                    ),
                }}
              >
                {g.title}
              </Typography.Title>
            </Row>
            <ChartGroupRenderer
              group={g}
              reports={props.participant.reports}
              provider={props.participant.reportFieldStore}
            />
            <AnnotationInputCard
              placeholder="Click here to write a description for this chart group"
              annotationKey={`chartGroup-${g.key}`}
              store={props.participant.annotationsStore}
            />
          </>
        ))}

        <Typography.Title level={3}>Conclusion</Typography.Title>
        <AnnotationInputCard
          placeholder="Conclusion Placeholder, Click here to Edit"
          annotationKey="conclusion"
          store={props.participant.annotationsStore}
        />
      </Space>
    </LoggedInTemplate>
  );
});

export default ReportBuilderPage;
