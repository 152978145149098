import {
  BehaviourOfConcernKey,
  IBehaviourOfConcern,
} from "../entities/behaviour-report/BehaviourOfConcern";
import {
  SurveyReport,
  isSurveyReportBehaviourOfConcern,
} from "../entities/survey-reports/SurveyReport";
import { localeCompareSort } from "../utils/sorters";

export class BehaviourOfConcernStore {
  readonly _behavioursOfConcern: Array<IBehaviourOfConcern> = [];

  private add(key: BehaviourOfConcernKey) {
    this._behavioursOfConcern.push({
      key,
      label: BehaviourOfConcernStore.keyToLabel(key),
    });
  }

  processReports(reports: readonly SurveyReport[]) {
    const behaviourKeys = reports.reduce((set, report) => {
      if (!isSurveyReportBehaviourOfConcern(report)) {
        return set;
      }

      report.behaviours.forEach((b) => set.add(b));
      return set;
    }, new Set<BehaviourOfConcernKey>());

    const sorted = new Array(...behaviourKeys.values()).sort(localeCompareSort);
    sorted.forEach((b) => this.add(b));
  }

  get keys(): readonly BehaviourOfConcernKey[] {
    return this._behavioursOfConcern.map((boc) => boc.key);
  }

  static keyToLabel(boc: BehaviourOfConcernKey): string {
    const suffix = boc.split("boc.")[1];
    const suffixReplaced = suffix
      .replace(/_/g, " - ")
      .replace(/-/g, " ")
      .replace(/\./g, " ");

    return suffixReplaced
      .split(" ")
      .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
      .join(" ");
  }

  static labelToKey(label: string): BehaviourOfConcernKey {
    if (label.startsWith("Other")) {
      const suffix = label.split("Other - ")[1];
      const dotCase = suffix.toLowerCase().replace(/ /g, ".");
      return `boc.other_${dotCase}`;
    }

    return `boc.${label
      .replaceAll(" - ", "_")
      .replaceAll(" ", ".")
      .toLowerCase()}` as BehaviourOfConcernKey;
  }
}
