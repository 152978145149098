import { Col, Row, Space, Table, Typography } from "antd";
import { DateTime } from "luxon";

import LoggedInTemplate from "../templates/LoggedInTemplate";
import type { ColumnsType } from "antd/es/table";
import {
  SurveyReport,
  SurveyReportCombined,
  SurveyReportField,
  filterReportsByDateRange,
} from "../../entities/survey-reports/SurveyReport";
import { ReactNode, useCallback, useMemo, useState } from "react";

import generatePicker from "antd/es/date-picker/generatePicker";
import luxonGenerateConfig from "rc-picker/lib/generate/luxon";
import { DateRange } from "../../entities/time/dateRange";
import {
  createReportTypeColumn,
  createTagColumn,
  createTextColumn,
} from "../../ui/table";
import { DateCard } from "../molecules/DateCard";
import { ReportFieldSelectorCard } from "../molecules/ReportFieldSelectorCard";
import { assertNever } from "../../utils/assertNever";
import { ParticipantStore } from "../../store/ParticipantStore";
import { ColumnFilterItem } from "antd/es/table/interface";
import { ReportValuesStore } from "../../store/ReportValuesStore";
import { localeCompareSort } from "../../utils/sorters";
import { ReportFieldStore } from "../../store/ReportFieldStore";

export const DatePicker = generatePicker<DateTime>(luxonGenerateConfig);

type Props = {
  participant: ParticipantStore;
};

function buildTableColumns(
  reportFieldStore: ReportFieldStore,
  reportValuesStore: ReportValuesStore
): ColumnsType<SurveyReportCombined> {
  // Start by sorting Field Definitions by their tableFixed value, "left" should go first, then undefined, then "right".
  // If they match, then sort by their original array index.
  const sortedFields = [...reportFieldStore.fields].sort((a, b) => {
    if (a.tableFixed === b.tableFixed) {
      return (
        reportFieldStore.fields.indexOf(a) - reportFieldStore.fields.indexOf(b)
      );
    }

    if (a.tableFixed === "left") {
      return -1;
    }

    if (a.tableFixed === "right") {
      return 1;
    }

    if (b.tableFixed === "left") {
      return 1;
    }

    if (b.tableFixed === "right") {
      return -1;
    }

    return 0;
  });

  return sortedFields.map((def) => {
    switch (def.renderType) {
      case "report-type":
        return createReportTypeColumn(def);
      case "text":
        return createTextColumn(def);
      case "tag":
        return createTagColumn(def, reportValuesStore);
      default:
        assertNever(def.renderType);
    }
  });
}

function buildFilterOptions(
  field: SurveyReportField,
  fieldStore: ReportFieldStore,
  valuesStore: ReportValuesStore
): ColumnFilterItem[] | undefined {
  const fieldOption = fieldStore.getReportFieldOption(field);
  const values = valuesStore.getValuesForField(field);
  if (!values) {
    return;
  }

  const sortedValues = [...values].sort(
    // @ts-ignore
    fieldOption.sortValues ?? localeCompareSort
  );

  return sortedValues.map(([v, meta]) => ({
    // @ts-ignore
    text: fieldOption.valueToLabel?.(v) ?? v,
    value: v,
  }));
}

const DEFAULT_COLUMNS: Array<SurveyReportField> = [
  "reportType",
  "incidentDate",
  "timeOfIncident",
  "durationOfIncident",
  "behaviours",
  "signsObserved",
  "settingEvents",
  "triggers",
  "actions",
];

const OverflowCell = ({ children, ...props }: { children: ReactNode }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <td
      {...props}
      style={{
        ...(props as any).style,
        position: "inherit",
        overflow: isHovering ? "visible" : "hidden",
      }}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      {children}
    </td>
  );
};

const ReportsFilterPage = ({ participant }: Props) => {
  const tableColumns = useMemo(
    () =>
      buildTableColumns(
        participant.reportFieldStore,
        participant.reportValuesStore
      ),
    [participant.reportFieldStore]
  );

  const [dateRange, setDateRange] = useState<DateRange | undefined>(
    participant.defaultDateRange
  );

  const resetDateRange = useCallback(() => {
    setDateRange(participant.defaultDateRange);
  }, [participant, setDateRange]);

  const [selectedCols, setSelectedCols] =
    useState<readonly SurveyReportField[]>(DEFAULT_COLUMNS);

  const resetCols = useCallback(() => {
    setSelectedCols(DEFAULT_COLUMNS);
  }, [setSelectedCols]);

  const tableData = useMemo(
    () => filterReportsByDateRange(participant.reports, dateRange),
    [(participant.reports, dateRange)]
  );

  const filteredColumns: ColumnsType<SurveyReport> = useMemo(() => {
    return [
      ...tableColumns
        .filter((c) =>
          (["reportType", ...selectedCols] as Array<String>).includes(
            c.key as string
          )
        )
        .map((c) => ({
          ...c,
          filters: buildFilterOptions(
            c.key as SurveyReportField,
            participant.reportFieldStore,
            participant.reportValuesStore
          ),
        })),
      Table.EXPAND_COLUMN,
    ];
  }, [tableColumns, selectedCols]);

  return (
    <LoggedInTemplate
      breadcrumbs={["Participants", participant.nameReadable, "STAR Filter"]}
    >
      <Typography.Title level={2}>STAR Filter</Typography.Title>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row gutter={[6, 6]} align="stretch">
          <Col span={12}>
            <DateCard
              range={dateRange}
              onRangeUpdate={setDateRange}
              onReset={resetDateRange}
              enabledDateRange={participant.defaultDateRange}
            />
          </Col>
          <Col span={12}>
            <ReportFieldSelectorCard
              title="Columns to Display"
              type="multiple"
              value={selectedCols}
              disabledOptions={["reportType"]}
              onFieldsChanged={setSelectedCols}
              resetClicked={resetCols}
              provider={participant.reportFieldStore}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table<SurveyReport>
              bordered
              // virtual
              size="middle"
              pagination={{
                defaultPageSize: 25,
                position: ["topRight"],
                size: "default",
                showTitle: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              // expandable={{
              //   showExpandColumn: true,
              //   fixed: "right",
              //   expandIconColumnIndex: filteredColumns.length,
              //   columnTitle: "Data",
              //   columnWidth: 75,

              //   expandedRowRender: (row) => <ReportDescription report={row} />,
              // }}
              dataSource={tableData}
              columns={filteredColumns}
              scroll={{ x: "100%", y: "100%" }}
              components={{ body: { cell: OverflowCell } }}
            />
          </Col>
        </Row>
      </Space>
    </LoggedInTemplate>
  );
};

export default ReportsFilterPage;
