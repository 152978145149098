import { makeAutoObservable } from "mobx";
import {} from "@ant-design/colors";
import {
  SurveyReport,
  SurveyReportCombined,
  SurveyReportField,
} from "../entities/survey-reports/SurveyReport";
import { ReportFieldStore } from "./ReportFieldStore";
import { randomColor } from "../ui/color";

type ReportValue = string | number;
type ReportValueMeta = {
  color: string;
};

export class ReportValuesStore {
  private readonly _cache: {
    [key in SurveyReportField]?: Map<ReportValue, ReportValueMeta>;
  } = {};

  constructor(private readonly reportFieldStore: ReportFieldStore) {
    makeAutoObservable(this);
  }

  public getValuesForField(
    field: SurveyReportField
  ): Map<ReportValue, ReportValueMeta> | undefined {
    return this._cache[field];
  }

  public getValueMetaForField(
    field: SurveyReportField,
    value: string | number
  ): ReportValueMeta | undefined {
    return this._cache[field]?.get(value);
  }

  public processRecord(record: SurveyReport) {
    const report = record as SurveyReportCombined;
    for (const field of Object.keys(report)) {
      const fieldOption = this.reportFieldStore.getReportFieldOption(field);
      if (!fieldOption || fieldOption.notFilterable) {
        continue;
      }

      if (this._cache[field] === undefined) {
        this._cache[field] = new Map();
      }

      const value = report[field];
      if (value === undefined) {
        continue;
      } else if (Array.isArray(value)) {
        for (const v of value as string[])
          (value as string[]).forEach((v) =>
            this._cache[field]!.set(v, {
              color: randomColor(),
            })
          );
      } else {
        this._cache[field]?.set(value, {
          color: randomColor(),
        });
      }
    }
  }
}
