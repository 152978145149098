import { Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { memo, useCallback } from "react";

declare global {
  interface Window {
      FreshworksWidget: (action: "open" | "hide", target?: string) => void;
  }
}

const FreshdeskWidget = () => {
  const ref = useCallback((elem: HTMLDivElement | null) => {
    if (!elem) return;
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.innerHTML = `
        window.fwSettings={
            'widget_id':51000003858
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        `;

    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://aus-widget.freshworks.com/widgets/51000003858.js";

    elem.appendChild(script1);
    elem.appendChild(script2);

    window.FreshworksWidget("hide", "launcher");
  }, []);

  const onClick = useCallback(() => {
    window.FreshworksWidget("open");
  }, []);

  return (
    <>
      <div ref={ref} />
      <Button icon={<QuestionCircleOutlined />} onClick={onClick}>
        Helpdesk
      </Button>
    </>
  );
};

export default memo(FreshdeskWidget);
