import { ActionSeverity } from "./ActionSeverity";
import { BehaviourOfConcernAction } from "../survey-reports/nominalTypes";

export type IBehaviourOfConcern = {
  key: BehaviourOfConcernKey;
  label: string;
};

export type BehaviourOfConcernKey =
  | "boc.food-related_compulsive-eating-of-food"
  | "boc.food-related_eating-non-food-items"
  | "boc.food-related_food-binging"
  | "boc.food-related_food-refusal"
  | "boc.harm-to-self_physical"
  | "boc.harm-to-self_wandering"
  | "boc.harm-to-self_suicidal"
  | "boc.harm-to-self_use-of-weapons"
  | "boc.harmful-sexualised-behaviour_others"
  | "boc.harmful-sexualised-behaviour_self"
  | "boc.physical-aggression_animals"
  | "boc.physical-aggression_others"
  | "boc.property-damage-or-destruction"
  | "boc.verbal-aggression"
  | "boc.withdrawal"
  | `boc.other_${string}`;

export function isBehaviourOfConcernKey(
  key: string
): key is BehaviourOfConcernKey {
  return key.startsWith("boc.");
}

export type BehaviourOfConcernActionShort =
  `${ActionSeverity} - ${BehaviourOfConcernAction}`;
