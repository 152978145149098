import { useCallback, useEffect, useState } from "react";

import {
  Alert,
  App,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Typography,
} from "antd";
import LoggedInTemplate from "../templates/LoggedInTemplate";
import readExcelBuffer from "../../entities/survey-reports/SurveyReportImport";
import {
  BackendApiCreateImportReq,
  BackendApiCreatePractitonerReq,
  useBackendApi,
} from "../../api/backend";
import { ParticipantStore } from "../../store/ParticipantStore";
import { PractitonerImportsTable } from "../organisms/PractitonerImportsTable";
import { ParticipantCorrectionsTable } from "../organisms/ParticipantCorrectionsTable";
import loadExcelBuffer from "../../entities/survey-reports/SurveyReportImport";

type Props = {
  onParticipantPreview: (participant: ParticipantStore) => void;
};

const DataImportPage = (props: Props) => {
  const [creatingPractitioner, setCreatingPractitioner] =
    useState<boolean>(false);
  const [creatingImport, setCreatingImport] = useState<boolean>(false);
  const [updatingCorrections, setUpdatingCorrections] = useState<
    { participantName: string; practitionerId: string } | undefined
  >(undefined);

  const [createPractitionerForm] =
    Form.useForm<BackendApiCreatePractitonerReq>();
  const [createImportForm] = Form.useForm<BackendApiCreateImportReq>();

  const onDownload = useCallback(async (formId: string) => {
    const url = `https://customervoice.microsoft.com/formapi/DownloadExcelFile.ashx?formid=${formId}`;
    window.open(url, "_blank");
  }, []);

  const [practitionerId, setPractitionerId] = useState<string | undefined>();

  const {
    triggerImport,
    createPractitioner,
    listPractitioners,
    createImportForPractitioner,
    loadReportForParticipant,
    loadCorrectionsForParticipantManual,
    toggleImportForPractitioner,
  } = useBackendApi();

  const createPractitionerMutation = createPractitioner();
  const createImportMutation = createImportForPractitioner();
  const practitioners = listPractitioners();
  const triggerImportMutation = triggerImport();
  const toggleImportMutation = toggleImportForPractitioner();

  const onCreatePractitioner = useCallback(
    async (req: BackendApiCreatePractitonerReq) => {
      await createPractitionerMutation.mutateAsync(req);
      createPractitionerForm.resetFields();
      setCreatingPractitioner(false);
    },
    [createPractitionerMutation, setCreatingPractitioner]
  );

  const onCreateImport = useCallback(
    async (req: BackendApiCreateImportReq) => {
      if (practitionerId === undefined) {
        return;
      }

      let parsedSurveyFormId = req.survey_id;
      if (req.survey_id.startsWith("http")) {
        parsedSurveyFormId = new URLSearchParams(
          req.survey_id.split("#")[1].replace("#", "?")
        ).get("SurveyReportId")!;
      }

      await createImportMutation.mutateAsync({
        participant_name: req.participant_name,
        survey_id: parsedSurveyFormId,
        user_id: practitionerId,
      });
      createImportForm.resetFields();
      setCreatingImport(false);
    },
    [createImportMutation, createImportForm, setCreatingImport, practitionerId]
  );

  const onOpenParticipant = useCallback(
    async (participantName: string) => {
      if (practitionerId === undefined) {
        return;
      }

      const [name, reports] = await loadReportForParticipant(
        participantName,
        practitionerId
      );

      const store = new ParticipantStore(name, reports);
      props.onParticipantPreview(store);
    },
    [practitionerId]
  );

  const onToggleParticipant = useCallback(
    async (participantName: string, enabled: boolean) => {
      if (practitionerId === undefined) {
        return;
      }

      await toggleImportMutation.mutateAsync({
        participant_name: participantName,
        practitioner_id: practitionerId,
        enabled,
      });
    },
    [practitionerId]
  );

  const onOpenCorrections = useCallback(
    async (participantName: string) => {
      if (!practitionerId) {
        return;
      }

      setUpdatingCorrections({ participantName, practitionerId });
    },
    [setUpdatingCorrections, practitionerId]
  );

  const onTest = useCallback(
    async (participantName: string) => {
      const [fileBlob] = await window.showOpenFilePicker({
        types: [
          {
            accept: {
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      });
      const blob = await (await fileBlob.getFile()).arrayBuffer();
      const corrections = await loadCorrectionsForParticipantManual(
        participantName,
        practitionerId!
      );

      const reports = await loadExcelBuffer(blob, corrections);
      const participant = new ParticipantStore(participantName, reports);
      props.onParticipantPreview(participant);
    },
    [props.onParticipantPreview, practitionerId]
  );

  return (
    <LoggedInTemplate breadcrumbs={["Customer Voice Import Tool"]}>
      <Col>
        <Card title="User Management">
          <Flex vertical gap={24}>
            <Flex justify="space-between">
              <Button.Group>
                <Button onClick={() => setCreatingPractitioner(true)}>
                  Create Practitioner
                </Button>
                <Button
                  onClick={() => triggerImportMutation.mutate()}
                  disabled={triggerImportMutation.isPending}
                >
                  Trigger Import
                </Button>
              </Button.Group>
              <Flex vertical>
                <Typography.Text strong>Select a Practitioner</Typography.Text>
                <Select
                  value={practitionerId}
                  onChange={setPractitionerId}
                  loading={practitioners.isLoading}
                  style={{ width: "100%" }}
                >
                  {...(practitioners.data ?? []).map((p) => (
                    <Select.Option value={p.user_id}>{p.name}</Select.Option>
                  ))}
                </Select>
              </Flex>
            </Flex>
            <PractitonerImportsTable
              practitionerId={practitionerId ?? ""}
              onOpen={onOpenParticipant}
              onToggle={onToggleParticipant}
              onCorrections={onOpenCorrections}
              onDownload={onDownload}
              onTest={onTest}
              onClose={() => setPractitionerId(undefined)}
              onCreate={() => setCreatingImport(true)}
            />
          </Flex>
        </Card>
      </Col>

      <Modal
        title="Create Practitioner"
        open={creatingPractitioner}
        destroyOnClose
        onCancel={(_) => setCreatingPractitioner(false)}
        footer={<></>}
      >
        <Form<BackendApiCreatePractitonerReq>
          form={createPractitionerForm}
          labelCol={{ span: 6 }}
          onFinish={onCreatePractitioner}
        >
          {createPractitionerMutation.error !== null && (
            <Alert
              message={`${createPractitionerMutation.error}`}
              type="error"
            />
          )}
          <Form.Item<BackendApiCreatePractitonerReq>
            label="Given Name"
            name="given_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<BackendApiCreatePractitonerReq>
            label="Family Name"
            name="family_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<BackendApiCreatePractitonerReq>
            label="Email"
            name="email"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Create Participant"
        open={creatingImport}
        destroyOnClose
        onCancel={(_) => setCreatingImport(false)}
        footer={<></>}
      >
        <Form<BackendApiCreateImportReq>
          form={createImportForm}
          labelCol={{ span: 8 }}
          onFinish={onCreateImport}
        >
          {createImportMutation.error !== null && (
            <Alert message={`${createImportMutation.error}`} type="error" />
          )}
          <Form.Item<BackendApiCreateImportReq>
            label="Form/Survey ID"
            name="survey_id"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<BackendApiCreateImportReq>
            label="Participant Name"
            name="participant_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Corrections"
        open={updatingCorrections !== undefined}
        destroyOnClose
        onCancel={(_) => setUpdatingCorrections(undefined)}
        footer={<></>}
        width={900}
      >
        {updatingCorrections && (
          <ParticipantCorrectionsTable
            participantName={updatingCorrections.participantName}
            practitionerId={updatingCorrections.practitionerId}
            onFinish={() => setUpdatingCorrections(undefined)}
          />
        )}
      </Modal>
    </LoggedInTemplate>
  );
};

export default DataImportPage;
