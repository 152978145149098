import { memo, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  getChartDataMultiple,
  getChartDataMultipleFromReportField,
} from "../../../ui/useChartData";
import { BaseChartProps, ChartCardWrapper } from "./ChartBase";

type Props = BaseChartProps & {
  data: Array<[string, Record<string, number>]>;
};

export default memo(function StackedBarChartCard(props: Props) {
  const chartData = useMemo(() => {
    if (props.reportField !== undefined) {
      return getChartDataMultipleFromReportField(
        props.data,
        "bar",
        props.reportField
      );
    } else {
      return getChartDataMultiple(props.data, "bar");
    }
  }, [props.reportField, props.data]);

  return (
    <ChartCardWrapper {...props}>
      <Bar
        data={chartData}
        redraw
        options={{
          responsive: true,
          aspectRatio: 1,
          scales: {
            y: {
              stacked: true,
              min: 0,
              ticks: {
                stepSize: 1,
              },
            },
            x: {
              stacked: true,
            },
          },
          plugins: {
            legend: {
              labels: {
                // generateLabels(chart) {
                //   const defaultLabels = this.generateLabels(chart);
                // },
              },
            },
          },
        }}
      />
    </ChartCardWrapper>
  );
});
