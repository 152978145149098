import { Scale } from "chart.js";
import {
  SurveyReport,
  SurveyReportField,
  valueOfFieldForReport,
} from "../survey-reports/SurveyReport";

export function totalsForReportFields(
  reports: readonly SurveyReport[],
  fields: SurveyReportField[] | SurveyReportField
): Array<[string, number]> {
  const fieldsArray = Array.isArray(fields) ? fields : [fields];
  const data = reports.reduce((acc, report) => {
    for (const field of fieldsArray) {
      const value = valueOfFieldForReport(field, report);
      if (value === undefined) {
        continue;
      }
      if (Array.isArray(value)) {
        for (const val of value) {
          const existingCount = acc[val] ?? 0;
          acc[val] = existingCount + 1;
        }
      } else {
        const existingCount = acc[value] ?? 0;
        acc[value] = existingCount + 1;
      }
    }
    return acc;
  }, {} as Record<string, number>);

  return Object.entries(data);
}

export function splitChartTickCallback(this: Scale, tickVal: string | number) {
  // Split the tickValue after 15 characters at the closest space,
  // returning a multi dimentional array containing the split words
  const tickStr =
    typeof tickVal === "number" ? this.getLabelForValue(tickVal) : tickVal;

  if (tickStr.length < 15) {
    return tickStr;
  }

  const split = tickStr.split(" ");
  const lines: Array<string> = [];

  for (const word of split) {
    if (lines.length == 0) {
      lines.push(word);
    } else if (lines[lines.length - 1].length + word.length > 15) {
      lines.push(word);
    } else {
      lines[lines.length - 1] += ` ${word}`;
    }
  }

  return lines;
}
