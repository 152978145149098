import { useCallback, useMemo } from "react";
import { ChartConfig, ChartKey } from "../../entities/chart/ChartConfig";
import {
  SurveyReport,
  SurveyReportField,
  filterReportsByDateRange,
  groupReportsByUnit,
} from "../../entities/survey-reports/SurveyReport";
import BarChartCard from "../molecules/charts/BarChartCard";
import PieChartCard from "../molecules/charts/PieChartCard";
import StackedBarChartCard from "../molecules/charts/StackedBarChartCard";
import { totalsForReportFields } from "../../entities/chart/ChartHelpers";
import { DateString } from "../../entities/time/DateString";
import { IReportFieldProvider } from "../../entities/survey-reports/ReportFields";

function stackedDataMapper(
  reportField: SurveyReportField
): (value: [DateString, SurveyReport[]]) => [string, Record<string, number>] {
  return ([day, reports]) => {
    return [
      day,
      Object.fromEntries(totalsForReportFields(reports, reportField)),
    ];
  };
}

export default function ChartCard(props: {
  config: ChartConfig;
  reports: readonly SurveyReport[];
  provider: IReportFieldProvider;
  onDelete?: (key: ChartKey) => void;
}) {
  const deleteCallback = useCallback(() => {
    props.onDelete?.(props.config.key);
  }, [props.onDelete, props.config.key]);

  const filteredReports = useMemo(
    () => filterReportsByDateRange(props.reports, props.config.dateRange),
    [props.reports, props.config.dateRange]
  );

  const reportFieldDef = props.provider.getReportFieldOption(
    props.config.reportField
  );

  switch (props.config.type) {
    case "bar-vertical":
    case "bar-horizontal": {
      const chartData = totalsForReportFields(
        filteredReports,
        props.config.reportField
      );

      return (
        <BarChartCard
          data={chartData}
          reportField={reportFieldDef}
          horizontal={props.config.type === "bar-horizontal"}
          title={props.config.title}
          description={props.config.description}
          onDelete={props.onDelete && deleteCallback}
        />
      );
    }
    case "pie": {
      const chartData = totalsForReportFields(
        filteredReports,
        props.config.reportField
      );

      return (
        <PieChartCard
          data={chartData}
          reportField={reportFieldDef}
          title={props.config.title}
          description={props.config.description}
          onDelete={props.onDelete && deleteCallback}
        />
      );
    }
    case "bar-stacked-day": {
      const groupedReports = groupReportsByUnit(filteredReports, "day");
      const groupedData = groupedReports.map<[string, Record<string, number>]>(
        stackedDataMapper(props.config.reportField)
      );
      return (
        <StackedBarChartCard
          data={groupedData}
          reportField={reportFieldDef}
          title={props.config.title}
          description={props.config.description}
          onDelete={props.onDelete && deleteCallback}
        />
      );
    }
    case "bar-stacked-week": {
      const groupedReports = groupReportsByUnit(filteredReports, "week");
      const groupedData = groupedReports.map<[string, Record<string, number>]>(
        stackedDataMapper(props.config.reportField)
      );

      return (
        <StackedBarChartCard
          data={groupedData}
          reportField={reportFieldDef}
          title={props.config.title}
          description={props.config.description}
          onDelete={props.onDelete && deleteCallback}
        />
      );
    }
    case "bar-stacked-month": {
      const groupedReports = groupReportsByUnit(filteredReports, "month");
      const groupedData = groupedReports.map<[string, Record<string, number>]>(
        stackedDataMapper(props.config.reportField)
      );

      return (
        <StackedBarChartCard
          data={groupedData}
          reportField={reportFieldDef}
          title={props.config.title}
          description={props.config.description}
          onDelete={props.onDelete && deleteCallback}
        />
      );
    }
  }
}
