import { Avatar, Row, Tag, Flex } from "antd";
import { ColumnType } from "antd/es/table";
import { assertNever } from "../utils/assertNever";
import {
  SurveyReportCombined,
  SurveyReportField,
  valueOfFieldForReport,
} from "../entities/survey-reports/SurveyReport";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { localeCompareSort } from "../utils/sorters";
import { ReportFieldDefinition } from "../entities/survey-reports/ReportFields";
import { ReportValuesStore } from "../store/ReportValuesStore";

const reportFilter =
  (field: SurveyReportField) =>
  (value: unknown, report: SurveyReportCombined) => {
    const fieldValue = report[field];
    if (Array.isArray(fieldValue)) {
      // @ts-ignore
      return fieldValue.includes(value);
    }

    return fieldValue === value;
  };

export function createTextColumn(
  def: ReportFieldDefinition
): ColumnType<SurveyReportCombined> {
  return {
    key: def.reportField,
    title: def.label,
    fixed: def.tableFixed,
    width: 150,

    render:
      def.valueToLabel !== undefined
        ? // @ts-ignore
          (_: unknown, record: R) => reportOption.valueToLabel?.(record[field])
        : undefined,

    dataIndex: def.reportField,

    filterSearch: !def.notFilterable,
    filterMultiple: !def.notFilterable,
    onFilter: def.notFilterable ? undefined : reportFilter(def.reportField),

    sorter: (a, b) =>
      // @ts-ignore
      def.sortValues?.(a[def.reportField], b[def.reportField]) ??
      // @ts-ignore
      localeCompareSort(a[def.reportField] ?? "", b[def.reportField] ?? ""),
  };
}

export function createReportTypeColumn(
  def: ReportFieldDefinition
): ColumnType<SurveyReportCombined> {
  return {
    ...createTextColumn(def),
    width: 100,
    render: (_, report) => {
      switch (report.reportType) {
        case "no-boc":
          return (
            <Flex
              justify="center"
              align="center"
              // @ts-ignore
              title={def.valueToLabel(report.reportType)}
            >
              <Avatar
                style={{ backgroundColor: "green" }}
                icon={<CheckCircleOutlined />}
              />
            </Flex>
          );
        case "escalation":
          return (
            <Flex justify="center" align="center">
              <Avatar
                // @ts-ignore
                title={def.valueToLabel(report.reportType)}
                style={{ backgroundColor: "gold" }}
                icon={<WarningOutlined />}
              />
            </Flex>
          );
        case "boc":
          return (
            <Flex justify="center" align="center">
              <Avatar
                // @ts-ignore
                title={def.valueToLabel(report.reportType)}
                style={{ backgroundColor: "red" }}
                icon={<CloseCircleOutlined />}
              />
            </Flex>
          );
        default:
          assertNever(report.reportType);
      }
    },
  };
}

export function createTagColumn(
  def: ReportFieldDefinition,
  reportValuesStore: ReportValuesStore
): ColumnType<SurveyReportCombined> {
  return {
    ...createTextColumn(def),
    width: 300,
    render: (_, row) => {
      const value = valueOfFieldForReport(def.reportField, row) as
        | Array<string>
        | undefined;

      return (
        <>
          {...(value ?? []).map((a) => {
            const t = reportValuesStore.getValueMetaForField(
              def.reportField,
              a
            );
            return (
              <Row style={{ marginBottom: "5px" }}>
                <Tag color={t?.color} key={a} title={a}>
                  {
                    // @ts-ignore
                    (def.valueToLabel?.(a) ?? a).toUpperCase()
                  }
                </Tag>
              </Row>
            );
          })}
        </>
      );
    },
  };
}
