import { Form, Select, Typography } from "antd";
import {
  ChartGroupKey,
  IChartGroupProvider,
} from "../../entities/chart/ChartGroup";
import { DefaultOptionType } from "antd/es/select";
import { useMemo } from "react";

export default function ChartTypeSelector(props: {
  groups: IChartGroupProvider["chartGroupKeysWithLabel"];
  value: ChartGroupKey;
  setChartGroup: (chartGroupKey: ChartGroupKey) => void;
}) {
  const options = useMemo(() => {
    return props.groups.map<DefaultOptionType>(([key, label]) => ({
      label,
      value: key,
    }));
  }, [props.groups]);

  return (
    <Form.Item
      label={<Typography.Text strong>Chart Group</Typography.Text>}
      style={{ width: "100%", marginBottom: "7px" }}
    >
      <Select
        style={{ width: "100%" }}
        options={options}
        value={props.value}
        onChange={(v) => props.setChartGroup(v)}
      />
    </Form.Item>
  );
}
