import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import {
  getChartDataSingle,
  getChartDataSingleFromReportField,
} from "../../../ui/useChartData";
import { BaseChartProps, ChartCardWrapper } from "./ChartBase";
import { memo, useMemo } from "react";
import { splitChartTickCallback } from "../../../entities/chart/ChartHelpers";

type Props = BaseChartProps & {
  data: Array<[string, number]>;
  horizontal?: boolean;
};

export default memo(function BarChartCard(props: Props) {
  const chartData = useMemo(() => {
    if (props.reportField !== undefined) {
      return getChartDataSingleFromReportField(
        props.data,
        "bar",
        props.reportField
      );
    } else {
      return getChartDataSingle(props.data, "bar");
    }
  }, [props.reportField, props.data]);

  return (
    <ChartCardWrapper {...props}>
      <Bar
        data={chartData}
        redraw
        options={{
          responsive: true,
          aspectRatio: props.aspectRatio ?? 1,
          indexAxis: props.horizontal === true ? "y" : "x",
          scales: {
            x: {
              min: 0,
              ticks: {
                stepSize: 1,
                align: "center",
                callback: splitChartTickCallback,
              },
            },
            y: {
              ticks: {
                align: "center",
                callback: splitChartTickCallback,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </ChartCardWrapper>
  );
});
