import { App, Col, Row, Space, Tabs, Typography } from "antd";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";

import LoggedInTemplate from "../templates/LoggedInTemplate";
import {
  SurveyReport,
  filterReportsByDateRange,
} from "../../entities/survey-reports/SurveyReport";
import { useCallback, useMemo, useState } from "react";
import { computed } from "mobx";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";

import generatePicker from "antd/es/date-picker/generatePicker";
import luxonGenerateConfig from "rc-picker/lib/generate/luxon";
import { Tab } from "rc-tabs/lib/interface";
import { DateRange } from "../../entities/time/dateRange";
import { DateCard } from "../molecules/DateCard";

import ChartBuilderCard from "../molecules/ChartBuilderCard";
import { ParticipantStore } from "../../store/ParticipantStore";
import { ChartConfig, ChartKey } from "../../entities/chart/ChartConfig";
import { totalsForReportFields } from "../../entities/chart/ChartHelpers";
import ChartCard from "../organisms/ChartCard";
import ChartGroupView from "../organisms/ChartGroupRenderer";
import { IReportFieldProvider } from "../../entities/survey-reports/ReportFields";

export const DatePicker = generatePicker<DateTime>(luxonGenerateConfig);

type PreviewConfig = Omit<ChartConfig, "dateRange" | "key" | "title">;

export function DateRangeToDatePickerValue(
  dateRange: DateRange | undefined
): [DateTime, DateTime] | null {
  if (!dateRange) {
    return null;
  }

  return [dateRange.start, dateRange.end];
}

export function DateRangeFromDatePickerValue(
  dateRange: [DateTime | null, DateTime | null] | null
): DateRange | undefined {
  if (!dateRange || !dateRange[0] || !dateRange[1]) {
    return undefined;
  }

  return {
    start: dateRange[0],
    end: dateRange[1],
  };
}

type Props = {
  participant: ParticipantStore;
};

function dateRangeToString(dateRange: DateRange) {
  return `${dateRange.start.toFormat("dd/MM/yyyy")} - ${dateRange.end.toFormat(
    "dd/MM/yyyy"
  )}`;
}

const PreviewTabBody = (props: {
  config: PreviewConfig;
  dateRange: DateRange;
  filteredReports: readonly SurveyReport[];
  provider: IReportFieldProvider;
}) => {
  return (
    <Row justify="space-around">
      <Col span={6}>
        <ChartCard
          config={{
            ...props.config,
            title: "Preview",
            description: dateRangeToString(props.dateRange),
            key: "Preview" as ChartKey,
            dateRange: props.dateRange,
          }}
          provider={props.provider}
          reports={props.filteredReports}
        />
      </Col>
    </Row>
  );
};

const ReportsChartPage = observer(({ participant }: Props) => {
  const { modal, notification } = App.useApp();

  const [activeTab, setActiveTab] = useState<string>("preview");

  const [dateRange, setDateRange] = useState<DateRange>(
    participant.defaultDateRange
  );

  const updateDateRange = useCallback(
    (range: DateRange | undefined) => {
      setActiveTab("preview");
      setDateRange(range ?? participant.defaultDateRange);
    },
    [participant, setDateRange]
  );

  const [previewConfig, setPreviewConfig] = useState<PreviewConfig>({
    reportField: "reportType",
    type: "bar-horizontal",
  });

  const updateConfig = useCallback(
    (config: PreviewConfig) => {
      setActiveTab("preview");
      setPreviewConfig(config);
    },
    [setActiveTab, setPreviewConfig]
  );

  const resetDateRange = useCallback(() => {
    setDateRange(participant.defaultDateRange);
  }, [participant, setDateRange]);

  const saveChartConfig = useCallback(
    (groupKey: string, config: Omit<ChartConfig, "dateRange" | "title">) => {
      if (!dateRange) {
        return;
      }

      const reportFieldOption =
        participant.reportFieldStore.getReportFieldOption(config.reportField);
      if (!reportFieldOption) {
        return;
      }

      const filteredReports = filterReportsByDateRange(
        participant.reports,
        dateRange
      );

      const chartData = totalsForReportFields(filteredReports, [
        config.reportField,
      ]);

      if (Object.keys(chartData).length === 0) {
        notification.error({
          message: "No data for field in the selected range",
          placement: "bottomRight",
        });
        return;
      }

      const configWithDateAndTitle: ChartConfig = {
        ...config,
        title: `${reportFieldOption.label}`,
        description: `${dateRangeToString(dateRange)}`,
        dateRange,
      };

      participant.chartConfigStore.addChartToGroup(
        groupKey,
        configWithDateAndTitle
      );

      setActiveTab(groupKey);
    },
    [participant.chartConfigStore, dateRange, activeTab]
  );

  const tabItems = useMemo(
    () =>
      computed(
        () =>
          [
            {
              key: "preview",
              label: "Preview",
              closable: false,
              children: (
                <PreviewTabBody
                  config={previewConfig}
                  dateRange={dateRange}
                  filteredReports={participant.reports}
                  provider={participant.reportFieldStore}
                />
              ),
            },
            ...participant.chartConfigStore.chartGroups.map((g) => ({
              key: g.key,
              label: g.title,
              children: (
                <ChartGroupView
                  group={g}
                  reports={participant.reports}
                  provider={participant.reportFieldStore}
                  onChartDelete={(g, c) =>
                    participant.chartConfigStore.removeChartFromGroup(g, c)
                  }
                />
              ),
            })),
          ] as Tab[]
      ),
    [
      previewConfig,
      dateRange,
      participant.reportFieldStore,
      participant.chartConfigStore.chartGroups,
    ]
  ).get();

  const onTabEdit = useCallback(
    (
      event: string | React.MouseEvent | React.KeyboardEvent,
      action: "add" | "remove"
    ) => {
      if (action === "add") {
        participant.chartConfigStore.addChartGroup();
      } else if (action === "remove") {
        const key = event as string;
        if (participant.chartConfigStore.chartGroups.length === 1) {
          notification.error({ message: "Cannot delete last chart group" });
          return;
        }
        const groupLength = participant.chartConfigStore.groupLength(key);
        if (groupLength === 0) {
          participant.chartConfigStore.removeChartGroup(key);
          if (key == activeTab) {
            setActiveTab("preview");
          }
          return;
        }

        modal.confirm({
          title: "Are you sure you want to delete this group?",
          content: `This group contains ${groupLength} Chart Configurations. This action cannot be undone.`,
          icon: <ExclamationCircleFilled />,
          cancelText: "No",
          okText: "Yes",
          onCancel: () => {},
          onOk: () => {
            participant.chartConfigStore.removeChartGroup(key);

            if (key == activeTab) {
              setActiveTab("preview");
            }
          },
        });
      }
    },
    [setActiveTab, activeTab]
  );

  return (
    <LoggedInTemplate
      breadcrumbs={[
        "Participants",
        participant.nameReadable,
        "Reporting Charts",
      ]}
    >
      <Typography.Title level={2}>Data Charts</Typography.Title>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row gutter={[6, 6]} align="stretch">
          <Col span={12}>
            <DateCard
              range={dateRange}
              onRangeUpdate={updateDateRange}
              onReset={resetDateRange}
              enabledDateRange={participant.defaultDateRange}
            />
          </Col>
          <Col span={12}>
            <ChartBuilderCard
              onChartCreated={saveChartConfig}
              onUpdated={updateConfig}
              createDisabled={dateRange === undefined}
              existingReports={participant.reports}
              reportFieldProvider={participant.reportFieldStore}
              chartGroupProvider={participant.chartConfigStore}
            />
          </Col>
        </Row>

        <Tabs
          type="editable-card"
          items={tabItems}
          onEdit={onTabEdit}
          activeKey={activeTab}
          onChange={setActiveTab}
        />
      </Space>
    </LoggedInTemplate>
  );
});

export default ReportsChartPage;
