import { DateTime } from "luxon";
import { ISO8601DateTimeString } from "./ISO8601String";

/** A Date String in the format `YYYY-MM-DD` or `1995-01-15` */
export type DateString = NominalPrimative<string, "DateString">;

/** A Week String in the format `YYYY-Www` or `1995-W03` */
export type WeekString = NominalPrimative<string, "WeekString">;

export function dateStringToLuxon(dateString: DateString): DateTime {
  return DateTime.fromFormat(dateString, "y-MM-dd");
}

export function dateStringFromLuxon(luxon: DateTime): DateString {
  return luxon.toFormat("y-MM-dd") as DateString;
}

export function dateStringFromISO(
  dateString: ISO8601DateTimeString
): DateString {
  return DateTime.fromISO(dateString).toFormat("y-MM-dd") as DateString;
}
