import { Line } from "react-chartjs-2";
import {
  getChartDataMultiple,
  getChartDataMultipleFromReportField,
  getChartDataSingle,
  getChartDataSingleFromReportField,
} from "../../../ui/useChartData";
import { memo, useMemo } from "react";
import { BaseChartProps, ChartCardWrapper } from "./ChartBase";
import { splitChartTickCallback } from "../../../entities/chart/ChartHelpers";

type Props = BaseChartProps &
  (
    | { type: "single"; data: Array<[string, string | number]> }
    | { type: "multiple"; data: Array<[string, Record<string, number>]> }
  );

export default memo(function LineChartCard(props: Props) {
  const chartData = useMemo(() => {
    if (props.type === "single") {
      if (props.reportField !== undefined) {
        return getChartDataSingleFromReportField(
          props.data,
          "line",
          props.reportField
        );
      } else {
        return getChartDataSingle(props.data, "line");
      }
    } else {
      if (props.reportField !== undefined) {
        return getChartDataMultipleFromReportField(
          props.data,
          "line",
          props.reportField
        );
      } else {
        return getChartDataMultiple(props.data, "line");
      }
    }
  }, [props.reportField, props.data, props.type]);

  return (
    <ChartCardWrapper {...props}>
      <Line
        data={chartData}
        redraw
        options={{
          responsive: true,
          aspectRatio: props.aspectRatio ?? 1.5,
          scales: {
            y: {
              min: 0,
              ticks: {
                stepSize: 1,
                callback: splitChartTickCallback,
              },
            },
            x: {
              ticks: {
                callback: splitChartTickCallback,
              },
            },
          },
          plugins: {
            legend: {
              display: props.type === "multiple",
            },
          },
        }}
      />
    </ChartCardWrapper>
  );
});
