export function buildSorterAgainstArray<T>(array: ReadonlyArray<T>) {
  return (a: T, b: T) => {
    const aIndex = array.indexOf(a);
    const bIndex = array.indexOf(b);
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  };
}

export function localeCompareSort(a: string | number, b: string | number) {
  return `${a}`.localeCompare(`${b}`, undefined, { numeric: true });
}
