import { faker } from "@faker-js/faker";
import { DateTime } from "luxon";

import {
  SurveyReport,
  SurveyReportBehaviourOfConcern,
  SurveyReportEscalationNoBehaviour,
  SurveyReportNoBehaviour,
  SurveyReportType,
} from "./SurveyReport";

import {
  BehaviourOfConcernActionShort,
  BehaviourOfConcernKey,
} from "../behaviour-report/BehaviourOfConcern";

import { INCIDENT_DURATION, IncidentDuration } from "./IncidentDuration";
import { INCIDENT_HOUR_RANGE, IncidentHourRange } from "./IncidentHourRange";
import {
  BehaviourOfConcernAction,
  EscalationSign,
  Person,
  PreventativeStrategy,
  ReactiveStrategy,
  ReplacementBehaviour,
  ReportLocation,
  ResultOfBehaviour,
  SettingEvent,
  Trigger,
} from "./nominalTypes";
import { FUNCTION_OF_BEHAVIOUR } from "./FunctionOfBehaviour";
import { ISO8601DateTimeString } from "../time/ISO8601String";
import { DateString } from "../time/DateString";
import { ActionSeverity } from "../behaviour-report/ActionSeverity";
import { REPORTING_TIME_HALF_HOUR } from "./ReportingTimeHalfHour";

const generateArrWithMap = <T>(
  map: (i: number) => string,
  length: number = 5
) =>
  Array(length)
    .fill(undefined)
    .map((_, i) => map(i) as T);

const number = (min: number, max: number): number =>
  faker.number.int({ min, max });

const randomElements = <T>(
  arr: readonly T[],
  countMin: number = 1,
  countMax = 3
) => faker.helpers.arrayElements(arr, number(countMin, countMax));

const ALL_BEHAVIOUR_OF_CONCERN_KEYS = [
  "boc.food-related_compulsive-eating-of-food",
  "boc.food-related_eating-non-food-items",
  "boc.food-related_food-binging",
  "boc.food-related_food-refusal",
  "boc.harm-to-self_physical",
  "boc.harm-to-self_wandering",
  "boc.harm-to-self_suicidal",
  "boc.harm-to-self_use-of-weapons",
  "boc.harmful-sexualised-behaviour_others",
  "boc.harmful-sexualised-behaviour_self",
  "boc.physical-aggression_animals",
  "boc.physical-aggression_others",
  "boc.property-damage-or-destruction",
  "boc.verbal-aggression",
  "boc.withdrawal",
] as const;

function actionTestPrefixForBehaviourKey(key: BehaviourOfConcernKey): string {
  switch (key) {
    case "boc.food-related_compulsive-eating-of-food":
      return "FRCompulsiveEatingName";
    case "boc.food-related_eating-non-food-items":
      return "FRNonFoodItemsName";
    case "boc.food-related_food-binging":
      return "FRBingingName";
    case "boc.food-related_food-refusal":
      return "FRRefusalName";
    case "boc.harm-to-self_physical":
      return "HSPhysicalName";
    case "boc.harm-to-self_wandering":
      return "HSWanderingName";
    case "boc.harm-to-self_suicidal":
      return "HSSuicidalName";
    case "boc.harm-to-self_use-of-weapons":
      return "HSWeaponsName";
    case "boc.harmful-sexualised-behaviour_others":
      return "HSBOthersName";
    case "boc.harmful-sexualised-behaviour_self":
      return "HSBSelfName";
    case "boc.physical-aggression_animals":
      return "PAAnimalsName";
    case "boc.physical-aggression_others":
      return "PAOthersName";
    case "boc.property-damage-or-destruction":
      return "PDDName";
    case "boc.verbal-aggression":
      return "VAName";
    case "boc.withdrawal":
      return "WithdrawalName";
    default:
      return "OtherName";
  }
}

const TEST_CONSTANTS = {
  SETTING_EVENTS: generateArrWithMap<SettingEvent>(
    (i) => `SettingEvent${i}`,
    10
  ),

  TRIGGERS: generateArrWithMap<Trigger>((i) => `Trigger${i}`, 10),
  PERSONS: generateArrWithMap<Person>((i) => `Person${i}`, 10),
  LOCATIONS: generateArrWithMap<ReportLocation>((i) => `Location${i}`, 5),

  PREVENTATIVE_STRATEGY: generateArrWithMap<PreventativeStrategy>(
    (i) => `PreventativeStrategy${i}`
  ),
  REACTIVE_STRATEGY: generateArrWithMap<ReactiveStrategy>(
    (i) => `ReactiveStrategy${i}`
  ),

  ESCALATION_SIGNS: generateArrWithMap<EscalationSign>(
    (i) => `EscalationSign${i}`
  ),

  REPLACEMENT_BEHAVIOURS: generateArrWithMap<ReplacementBehaviour>(
    (i) => `ReplacementBehaviour${i}`
  ),
};

function createReportBase<R extends SurveyReportType>(
  reportType: R,
  startDate: DateTime
): Omit<SurveyReportNoBehaviour, "reportType"> & { reportType: R } {
  const reportTimestamp = DateTime.fromJSDate(
    faker.date.between({
      from: startDate.toJSDate(),
      to: startDate.plus({ week: 1 }).toJSDate(),
    })
  );
  const firstName = faker.person.firstName();
  const lastName = faker.person.lastName();

  const replacementBehaviours = randomElements(
    TEST_CONSTANTS.REPLACEMENT_BEHAVIOURS,
    0
  );

  const startTimeWithClient = faker.helpers.arrayElement(
    REPORTING_TIME_HALF_HOUR
  );
  const endTimeWithClient =
    REPORTING_TIME_HALF_HOUR[
      REPORTING_TIME_HALF_HOUR.indexOf(startTimeWithClient) + 3
    ];

  return {
    reportType,
    formResponseId: faker.string.uuid(),
    formStartTime: reportTimestamp.toISO()! as ISO8601DateTimeString,
    formCompletionTime: reportTimestamp
      .plus({ minutes: 3 })
      .toISO()! as ISO8601DateTimeString,

    nameOfPersonCompleting: `${firstName} ${lastName}`,

    incidentDate: reportTimestamp.startOf("day").toISODate()! as DateString,

    startTimeWithClient,
    endTimeWithClient,
    replacementBehaviours,
  };
}

function createSurveyReportNoBehaviour(
  startDate: DateTime
): SurveyReportNoBehaviour {
  return createReportBase("no-boc", startDate);
}

function createSurveyReportEscalationNoBehaviour(
  startDate: DateTime
): SurveyReportEscalationNoBehaviour {
  const reportBase = createReportBase("escalation", startDate);
  const [timeOfIncident, durationOfIncident] =
    calculateTimeAndDurationOfIncident(startDate);

  return {
    ...reportBase,
    timeOfIncident,
    durationOfIncident,

    triggers: randomElements(TEST_CONSTANTS.TRIGGERS, 1, 3),
    settingEvents: randomElements(TEST_CONSTANTS.SETTING_EVENTS, 1, 3),

    location: randomElements(TEST_CONSTANTS.LOCATIONS, 1, 1)[0],
    personsImpacted: randomElements(TEST_CONSTANTS.PERSONS, 1, 3),
    possibleFunctions: randomElements(FUNCTION_OF_BEHAVIOUR, 1, 2),

    signsObserved: randomElements(TEST_CONSTANTS.ESCALATION_SIGNS, 1, 3),
    preventativeStrategiesUsed: randomElements(
      TEST_CONSTANTS.PREVENTATIVE_STRATEGY,
      number(1, 3)
    ),
    reactiveStrategiesUsed: randomElements(
      TEST_CONSTANTS.REACTIVE_STRATEGY,
      number(1, 3)
    ),
  };
}

function createBehaviourOfConcernReport(
  startDate: DateTime
): SurveyReportBehaviourOfConcern {
  const reportBase = createReportBase("boc", startDate);
  const [timeOfIncident, durationOfIncident] =
    calculateTimeAndDurationOfIncident(startDate);

  let report: SurveyReportBehaviourOfConcern = {
    ...reportBase,
    reportType: "boc",

    timeOfIncident,
    durationOfIncident,

    location: randomElements(TEST_CONSTANTS.LOCATIONS, 1, 1)[0],
    personsImpacted: randomElements(TEST_CONSTANTS.PERSONS, 1, 3),
    possibleFunctions: randomElements(FUNCTION_OF_BEHAVIOUR, 1, 2),

    settingEvents: randomElements(TEST_CONSTANTS.SETTING_EVENTS, 2, 3),
    triggers: randomElements(TEST_CONSTANTS.TRIGGERS, 2, 4),

    preventativeStrategiesUsed: randomElements(
      TEST_CONSTANTS.PREVENTATIVE_STRATEGY,
      1,
      3
    ),
    reactiveStrategiesUsed: randomElements(
      TEST_CONSTANTS.REACTIVE_STRATEGY,
      1,
      3
    ),

    behaviours: [],
    actions: [],
    resultOfBehaviour: faker.lorem.sentences({
      min: 1,
      max: 4,
    }) as ResultOfBehaviour,
  };

  const behavioursOfConcern = randomElements(
    ALL_BEHAVIOUR_OF_CONCERN_KEYS,
    3,
    3
  );

  behavioursOfConcern.forEach((key) => {
    const actions = faker.helpers
      .arrayElements([1, 2, 3, 4, 5], number(2, 4))
      .map(
        (i) =>
          `${actionTestPrefixForBehaviourKey(
            key
          )}${i}` as BehaviourOfConcernAction
      );

    const severity = `${number(1, 4)}` as ActionSeverity;
    report.behaviours.push(key);

    const shortString =
      `${severity} - ${actions}` as BehaviourOfConcernActionShort;
    report.actions.push(shortString);

    report[`${key}.actions`] = actions;
    report[`${key}.severity`] = severity;
  });

  return report;
}

function calculateTimeAndDurationOfIncident(
  startDate: DateTime
): [IncidentHourRange, IncidentDuration] {
  const timeOfIncident = faker.helpers.arrayElement(INCIDENT_HOUR_RANGE);
  const duration = faker.helpers.arrayElement(INCIDENT_DURATION);

  return [timeOfIncident, duration];
}

export function createParticipantTestReports(): SurveyReport[] {
  const reports: SurveyReport[] = [];
  // Start at the beginning of last month
  // End at the start of the week
  const startDate = DateTime.now().startOf("month").minus({ month: 1 });
  const endDate = DateTime.now().startOf("week");

  for (
    let currentDate = startDate;
    currentDate < endDate;
    currentDate = currentDate.plus({ day: 1 })
  ) {
    if (Math.random() < 0.1) {
      reports.push(createSurveyReportNoBehaviour(currentDate));
      continue;
    }
    // Create 1 Escalation & 3 Behaviour reports per day
    reports.push(
      createSurveyReportEscalationNoBehaviour(currentDate),
      createBehaviourOfConcernReport(currentDate),
      createBehaviourOfConcernReport(currentDate),
      createBehaviourOfConcernReport(currentDate)
    );
  }

  return reports;
}
