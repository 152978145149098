import { Col, Row } from "antd";

import { ChartGroup } from "../../entities/chart/ChartGroup";
import ChartCard from "./ChartCard";
import { SurveyReport } from "../../entities/survey-reports/SurveyReport";
import { IReportFieldProvider } from "../../entities/survey-reports/ReportFields";
import { observer } from "mobx-react-lite";
import { ChartKey } from "../../entities/chart/ChartConfig";

type Props = {
  group: ChartGroup;
  reports: readonly SurveyReport[];
  provider: IReportFieldProvider;
  onChartDelete?: (groupKey: string, chartKey: ChartKey) => void;
};

const ChartGroupRenderer = observer((props: Props) => {
  return (
    <Row align="stretch" gutter={[6, 6]}>
      {props.group.charts.map((chart) => (
        <Col md={12} lg={6}>
          <ChartCard
            config={chart}
            reports={props.reports}
            key={chart.key}
            provider={props.provider}
            onDelete={() => props.onChartDelete?.(props.group.key, chart.key)}
          />
        </Col>
      ))}
    </Row>
  );
});

export default ChartGroupRenderer;
