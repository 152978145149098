import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { PropsWithChildren, useMemo } from "react";
import { ReportFieldDefinition } from "../../../entities/survey-reports/ReportFields";

type BaseChartCallbacks = {
  onDelete?: () => void;
};

export type BaseChartProps = {
  title?: string;
  description?: string;
  reportField?: ReportFieldDefinition;
  aspectRatio?: number;
} & BaseChartCallbacks;

export const useCommonChartButtons = (props: BaseChartCallbacks) => {
  if (!props.onDelete) {
    return undefined;
  }
  return (
    <Button.Group>
      {props.onDelete && (
        <Button
          type="default"
          danger
          children="Remove"
          onClick={props.onDelete}
          icon={<CloseCircleOutlined />}
        />
      )}
    </Button.Group>
  );
};

export const ChartCardWrapper = (props: PropsWithChildren<BaseChartProps>) => {
  const extras = useCommonChartButtons(props);

  const title = useMemo(() => {
    if (props.description) {
      return (
        <Card.Meta
          title={props.title ?? ""}
          description={props.description ?? "\xa0"}
          style={{ padding: "10px 0" }}
        />
      );
    } else {
      return props.title ?? "";
    }
  }, [props.title, props.description]);

  return (
    <Card
      title={title}
      actions={extras ? [extras] : undefined}
      style={{ height: "100%" }}
    >
      {props.children}
    </Card>
  );
};
