import { App, Button, Card, Form, Select, Spin } from "antd";
import { useBackendApi } from "../../api/backend";
import { ParticipantStore } from "../../store/ParticipantStore";
import LoggedInTemplate from "../templates/LoggedInTemplate";
import { useCallback, useMemo, useState } from "react";
import { createParticipantTestReports } from "../../entities/survey-reports/SurveyReport.testdata";

type Props = {
  onParticipantLoaded: (participant: ParticipantStore) => void;
  swapToExcelPage: () => void;
};

function formatName(rawName: string): string {
  return rawName
    .split(".")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
}

const IS_DEV = import.meta.env.DEV;
const LoadParticipantPage = (props: Props) => {
  const { notification } = App.useApp();
  const { listParticipantReports, loadParticipantReport } = useBackendApi();
  const [reportId, setReportId] = useState<string | undefined>();

  const onLoad = useCallback(async () => {
    if (!reportId) {
      return;
    }

    if (reportId === "TEST_DATA") {
      const participant = new ParticipantStore(
        "Test Participant",
        createParticipantTestReports()
      );

      props.onParticipantLoaded(participant);
      return;
    }

    const [name, reports] = await loadParticipantReport(reportId);
    if (reports.length === 0) {
      notification.warning({
        message: "No reports for Participant",
        description: (
          <div>
            The selected participant has no data available. Please contact{" "}
            <a href="emailto://hello@fabdacs.co">FABDACS (hello@fabdacs.co)</a>{" "}
            for support
          </div>
        ),
      });
      return;
    }

    const participant = new ParticipantStore(name, reports);
    props.onParticipantLoaded(participant);
  }, [reportId, loadParticipantReport, props.onParticipantLoaded]);

  const { data, error, status } = listParticipantReports();

  const selectItems = useMemo(() => {
    if (status !== "success" || !data) {
      return [];
    }

    const items = data.map((r) => ({
      label: `${formatName(r.name)} - ${r.report_id}`,
      value: `${r.name}_${r.report_id}`,
    }));

    if (IS_DEV) {
      items.push({ label: "TEST DATA", value: "TEST_DATA" });
    }
    return items;
  }, [data, status]);

  return (
    <LoggedInTemplate breadcrumbs={[]} hidePractitionerMenuItems>
      <Card title="Load Participant">
        <Spin spinning={status !== "success"}>
          <Form labelCol={{ span: 2 }}>
            <Form.Item label="Participant">
              <Select
                value={reportId}
                onChange={setReportId}
                options={selectItems}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 2, span: 14 }}>
              <Button
                type="primary"
                disabled={reportId === undefined}
                onClick={onLoad}
              >
                Load
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </LoggedInTemplate>
  );
};

export default LoadParticipantPage;
