export function median(numbers: number[]): number {
  const sortedNumbers = numbers.sort((a, b) => a - b);
  const middleIndex = Math.floor(sortedNumbers.length / 2);
  if (sortedNumbers.length % 2 === 0) {
    // If the array has an even number of elements, average the middle two
    return (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
  } else {
    // If the array has an odd number of elements, return the middle element
    return sortedNumbers[middleIndex];
  }
}

export function mean(numbers: number[]): number {
  return numbers.reduce((a, b) => a + b) / numbers.length;
}
