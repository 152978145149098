import { Card, Form, Typography } from "antd";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { DateRange } from "../../entities/time/dateRange";
import { DateRangeBuilder } from "./DateRangeBuilder";
import { DatePicker } from "../pages/ReportsChartPage";

type RangeValue<T> = [T | null, T | null];

export const disabledDate = (current: DateTime, range: DateRange) => {
  // Can not select days after today and before the startRange, if it is provided
  return (
    current > DateTime.local().endOf("day") ||
    (range.start !== undefined && current < range.start) ||
    (range.end !== undefined && current > range.end)
  );
};

export const DateCard = (props: {
  range: DateRange | undefined;
  onRangeUpdate: (range: DateRange | undefined) => void;
  onReset: () => void;
  enabledDateRange: DateRange;
}) => {
  const rangeValue = useMemo<[DateTime, DateTime] | null>(
    () => (props.range ? [props.range.start, props.range.end] : null),
    [props.range]
  );

  const disabledDateCallback = useCallback(
    (dt: DateTime) => {
      return props.enabledDateRange !== undefined
        ? disabledDate(dt, props.enabledDateRange)
        : true;
    },
    [props.enabledDateRange]
  );

  const changeCallback = useCallback(
    (newRange: RangeValue<DateTime>) => {
      if (!newRange || newRange[0] === null || newRange[1] === null) {
        props.onRangeUpdate(undefined);
        return;
      }

      props.onRangeUpdate({
        start: newRange[0],
        end: newRange[1],
      });
    },
    [props.onRangeUpdate]
  );

  return (
    <Card title="Dates for Chart">
      <Form layout="vertical">
        <Form.Item
          label={<Typography.Text strong>Date Range</Typography.Text>}
          style={{ marginBottom: "7px" }}
        >
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            disabledDate={disabledDateCallback}
            value={rangeValue}
            onChange={changeCallback}
          />
        </Form.Item>
        <Form.Item
          label={<Typography.Text strong>Date Range Builder</Typography.Text>}
          style={{ marginBottom: 0 }}
        >
          <DateRangeBuilder
            onUpdate={props.onRangeUpdate}
            onReset={props.onReset}
            firstReportDate={props.enabledDateRange.start}
            lastReportDate={props.enabledDateRange.end}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};
