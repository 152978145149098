import { makeAutoObservable } from "mobx";
import {
  SurveyReport,
  defaultDateRangeForReports,
} from "../entities/survey-reports/SurveyReport";
import { ReportValuesStore } from "./ReportValuesStore";
import { ChartConfigStore } from "./ChartConfigStore";
import { AnnotationsStore } from "./AnnotationsStore";
import { BehaviourOfConcernStore } from "./BehaviourOfConcernStore";
import { ReportFieldStore } from "./ReportFieldStore";
import { localeCompareSort } from "../utils/sorters";

export class ParticipantStore {
  readonly nameRaw: string;
  readonly nameReadable: string;
  readonly reports: readonly SurveyReport[] = [];

  readonly behaviourOfConcernStore: BehaviourOfConcernStore;
  readonly chartConfigStore: ChartConfigStore;
  readonly reportFieldStore: ReportFieldStore;
  readonly reportValuesStore: ReportValuesStore;
  readonly annotationsStore: AnnotationsStore;

  constructor(nameRaw: string, reports: readonly SurveyReport[]) {
    this.nameRaw = nameRaw;
    this.nameReadable = nameRaw
      .replaceAll(".", " ")
      .split(" ")
      .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
      .join(" ");

    this.reports = reports
      .slice()
      .sort((a, b) => localeCompareSort(a.incidentDate, b.incidentDate));

    this.chartConfigStore = new ChartConfigStore();
    this.annotationsStore = new AnnotationsStore();

    this.behaviourOfConcernStore = new BehaviourOfConcernStore();
    this.behaviourOfConcernStore.processReports(reports);

    this.reportFieldStore = new ReportFieldStore(
      this.behaviourOfConcernStore._behavioursOfConcern
    );
    this.reportValuesStore = new ReportValuesStore(this.reportFieldStore);

    reports.forEach((r) => {
      this.reportValuesStore.processRecord(r);
    });

    makeAutoObservable(this);
  }

  get defaultDateRange() {
    return defaultDateRangeForReports(this.reports);
  }
}
