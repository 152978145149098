import { DateTime, DateTimeUnit } from "luxon";

import {
  BehaviourOfConcernActionShort,
  BehaviourOfConcernKey,
} from "../behaviour-report/BehaviourOfConcern";
import { IncidentDuration } from "./IncidentDuration";
import { IncidentHourRange } from "./IncidentHourRange";
import { FunctionOfBehaviour } from "./FunctionOfBehaviour";
import {
  BehaviourOfConcernAction,
  BehaviourOfConcernActionNew,
  EscalationSign,
  Person,
  PreventativeStrategy,
  PreventativeStrategyNew,
  ReactiveStrategy,
  ReactiveStrategyNew,
  ReplacementBehaviour,
  ReportLocation,
  ResultOfBehaviour,
  SettingEvent,
  SettingEventNew,
  Trigger,
  TriggerNew,
} from "./nominalTypes";
import { DateRange } from "../time/dateRange";
import { ISO8601DateTimeString } from "../time/ISO8601String";
import { DateString, dateStringFromLuxon } from "../time/DateString";
import dateTimeUnitRange from "../time/dateTimeUnitRange";
import { ActionSeverity } from "../behaviour-report/ActionSeverity";
import { ReportingTimeHalfHour } from "./ReportingTimeHalfHour";

export type SurveyReportType = "no-boc" | "escalation" | "boc";

type SurveyReportBase<R extends SurveyReportType> = {
  reportType: R;
  formResponseId: string;
  formStartTime: ISO8601DateTimeString;
  formCompletionTime: ISO8601DateTimeString;
  nameOfPersonCompleting: string;
  incidentDate: DateString;
  startTimeWithClient: ReportingTimeHalfHour;
  endTimeWithClient: ReportingTimeHalfHour;

  // FERBS Replacement behaviours exist in all reports
  replacementBehaviours: Array<ReplacementBehaviour>;
};

export type SurveyReportNoBehaviour = SurveyReportBase<"no-boc">;

type SurveyReportWithIncidentBase = {
  timeOfIncident: IncidentHourRange;
  durationOfIncident: IncidentDuration;

  location: ReportLocation;
  settingEvents: Array<SettingEvent>;
  triggers: Array<Trigger>;

  personsImpacted: Array<Person>;
  possibleFunctions: Array<FunctionOfBehaviour>;

  // Stage 1 & 2
  preventativeStrategiesUsed: Array<PreventativeStrategy>;

  // Stage 3 & 4
  reactiveStrategiesUsed: Array<ReactiveStrategy>;

  // New / Other text fields
  settingEventsNew?: SettingEventNew;
  triggersNew?: TriggerNew;
  preventativeStrategiesNew?: PreventativeStrategyNew;
  reactiveStrategiesNew?: ReactiveStrategyNew;
  behaviourActionsNew?: BehaviourOfConcernActionNew;
};

export type SurveyReportEscalationNoBehaviour = SurveyReportBase<"escalation"> &
  SurveyReportWithIncidentBase & {
    signsObserved: Array<EscalationSign>;
  };

export type SurveyReportBehaviourOfConcern = {
  behaviours: Array<BehaviourOfConcernKey>;
  resultOfBehaviour: ResultOfBehaviour;
  actions: Array<BehaviourOfConcernActionShort>;
} & {
  [BocKey in `${BehaviourOfConcernKey}.actions`]?: Array<BehaviourOfConcernAction>;
} & {
  [BocKey in `${BehaviourOfConcernKey}.severity`]?: ActionSeverity;
} & SurveyReportWithIncidentBase &
  SurveyReportBase<"boc">;

export type SurveyReport =
  | SurveyReportNoBehaviour
  | SurveyReportEscalationNoBehaviour
  | SurveyReportBehaviourOfConcern;

export type SurveyReportCombined = Omit<SurveyReportNoBehaviour, "reportType"> &
  Omit<SurveyReportEscalationNoBehaviour, "reportType"> &
  Omit<SurveyReportBehaviourOfConcern, "reportType"> & {
    reportType: SurveyReportType;
  };

export type SurveyReportField = keyof SurveyReportCombined;

export const isSurveyReportBehaviourOfConcern = (
  x: SurveyReport
): x is SurveyReportBehaviourOfConcern => x.reportType === "boc";

export const defaultDateRangeForReports = (
  reports: readonly SurveyReport[]
): DateRange => {
  const sortedReports = reports
    .slice()
    .sort(
      (a, b) =>
        DateTime.fromISO(a.incidentDate).valueOf() -
        DateTime.fromISO(b.incidentDate).valueOf()
    );

  const firstReportTimestamp = DateTime.fromISO(sortedReports[0].incidentDate);

  const lastReportTimestamp = DateTime.fromISO(
    sortedReports[sortedReports.length - 1].incidentDate
  );

  return {
    start: firstReportTimestamp,
    end: lastReportTimestamp,
  };
};

export function groupReportsByUnit(
  reports: readonly SurveyReport[],
  unit: DateTimeUnit = "day"
): Array<[DateString, SurveyReport[]]> {
  const dateRange = defaultDateRangeForReports(reports);
  const days = dateTimeUnitRange(dateRange, unit);
  return days.map((date) => {
    return [
      dateStringFromLuxon(date),
      reports.filter((report) =>
        date.hasSame(DateTime.fromISO(report.incidentDate), unit)
      ),
    ];
  });
}

export function filterReportsByDateRange<Report extends SurveyReport>(
  reports: readonly Report[],
  dateRange: DateRange | undefined
): readonly Report[] {
  if (!dateRange) {
    return reports;
  }

  return reports.filter((report) => {
    const incidentDate = DateTime.fromISO(report.incidentDate);
    return (
      incidentDate >= dateRange.start.startOf("day") &&
      incidentDate <= dateRange.end.endOf("day")
    );
  });
}

export function valueOfFieldForReport(
  field: SurveyReportField,
  report: SurveyReport
) {
  return (report as SurveyReportCombined)[field];
}
