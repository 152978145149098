/**
 * Returns a count for each value returned from keyFn on arr.
 * @param keyFn A function that performs a lookup on each element of arr. Returning one or more keys.
 * @returns A record of key to count.
 */
export function countBy<T, K extends string>(
  arr: readonly T[],
  keyFn: (elem: T) => K | K[]
): Record<K, number> {
  return arr.reduce((acc, elem) => {
    const k = keyFn(elem);
    if (Array.isArray(k)) {
      k.forEach((k) => (acc[k] = (acc[k] || 0) + 1));
    } else {
      acc[k] = (acc[k] || 0) + 1;
    }
    return acc;
  }, {} as Record<K, number>);
}

export function groupBy<T, K extends string>(
  arr: readonly T[],
  key: (elem: T) => K
): Record<K, T[]> {
  return arr.reduce((acc, elem) => {
    const k = key(elem);
    acc[k] = [...(acc[k] || []), elem];
    return acc;
  }, {} as Record<K, T[]>);
}
