import { createContext, useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App } from "antd";

import ParticipantOverview from "./stories/pages/ParticipantOverview";
import ReportsFilterPage from "./stories/pages/ReportsFilterPage";
import ReportsChartPage from "./stories/pages/ReportsChartPage";
import { assertNever } from "./utils/assertNever";
import DataImportPage from "./stories/pages/DataImportPage";
import React from "react";
import { ParticipantStore } from "./store/ParticipantStore";
import LoggedOutPage from "./stories/pages/LoggedOutPage";
import LoadParticipantPage from "./stories/pages/LoadParticipantPage";
import ReportBuilderPage from "./stories/pages/ReportBuilderPage";

export type FabdacsPageState =
  | { page: "home" }
  | { page: "data-import" }
  | { page: "load-participant" }
  | { page: "overview"; participant: ParticipantStore }
  | { page: "star-filter"; participant: ParticipantStore }
  | { page: "chart-builder"; participant: ParticipantStore }
  | { page: "report-builder"; participant: ParticipantStore };

export type FabdacsPage = FabdacsPageState["page"];

export const API_URL = import.meta.env.VITE_API_URL;

const queryClient = new QueryClient();

interface PageStateContextType {
  pageState: FabdacsPageState;
  setPageState: (pageState: FabdacsPageState) => void;
}

export const PageStateContext = createContext<PageStateContextType>(
  undefined as unknown as PageStateContextType
);

function AppInner(props: {
  state: FabdacsPageState;
  updateState: (newState: FabdacsPageState) => void;
}) {
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (props.state.page !== "home") {
      return;
    }
    const roles = user?.["https://app-preview.fabdacs.co/roles"];

    if (roles?.includes("admin")) {
      props.updateState({ page: "data-import" });
    } else if (roles?.includes("practitioner")) {
      props.updateState({ page: "load-participant" });
    }
  }, [user, props.state.page, props.updateState]);

  if (isLoading || error || !isAuthenticated || props.state.page === "home") {
    return <LoggedOutPage errorMessage={error?.message} />;
  }

  switch (props.state.page) {
    case "data-import":
      return (
        <DataImportPage
          onParticipantPreview={(p) =>
            props.updateState({ page: "overview", participant: p })
          }
        />
      );
    case "load-participant":
      return (
        <LoadParticipantPage
          onParticipantLoaded={(p) =>
            props.updateState({ page: "overview", participant: p })
          }
          swapToExcelPage={() => props.updateState({ page: "data-import" })}
        />
      );
    case "overview":
      return <ParticipantOverview participant={props.state.participant} />;
    case "star-filter":
      return <ReportsFilterPage participant={props.state.participant} />;
    case "chart-builder":
      return <ReportsChartPage participant={props.state.participant} />;
    case "report-builder":
      return <ReportBuilderPage participant={props.state.participant} />;
    default:
      assertNever(props.state);
  }
}

function FabdacsApp() {
  const [pageState, setPageState] = useState<FabdacsPageState>({
    page: "home",
  });

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN!}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID!}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        scope:
          "profile email openid offline_access https://app-preview.fabdacs.co/roles",
      }}
      useRefreshTokens
    >
      <React.StrictMode>
        <App>
          <QueryClientProvider client={queryClient}>
            <PageStateContext.Provider value={{ pageState, setPageState }}>
              <AppInner state={pageState} updateState={setPageState} />
            </PageStateContext.Provider>
          </QueryClientProvider>
        </App>
      </React.StrictMode>
    </Auth0Provider>
  );
}

export default Sentry.withProfiler(FabdacsApp);
