import { useMemo } from "react";
import { Col, Divider, Flex, Row } from "antd";

import LoggedInTemplate from "../templates/LoggedInTemplate";

import {
  FUNCTION_OF_BEHAVIOUR,
  FunctionOfBehaviour,
} from "../../entities/survey-reports/FunctionOfBehaviour";
import {
  INCIDENT_DURATION,
  IncidentDuration,
} from "../../entities/survey-reports/IncidentDuration";
import {
  INCIDENT_HOUR_RANGE,
  IncidentHourRange,
} from "../../entities/survey-reports/IncidentHourRange";
import BarChartCard from "../molecules/charts/BarChartCard";
import NumberHighlightCard from "../molecules/NumberHighlightCard";
import {
  SurveyReport,
  isSurveyReportBehaviourOfConcern,
  defaultDateRangeForReports,
  SurveyReportBehaviourOfConcern,
} from "../../entities/survey-reports/SurveyReport";
import { mean, median } from "../../utils/math";
import { DateRange } from "../../entities/time/dateRange";
import dateTimeUnitRange from "../../entities/time/dateTimeUnitRange";
import {
  ActionSeverity,
  ActionSeverityInt,
} from "../../entities/behaviour-report/ActionSeverity";
import { ParticipantStore } from "../../store/ParticipantStore";
import {
  dateStringFromLuxon,
  dateStringToLuxon,
} from "../../entities/time/DateString";
import LineChartCard from "../molecules/charts/LineChartCard";
import { BehaviourOfConcernKey } from "../../entities/behaviour-report/BehaviourOfConcern";
import { countBy, groupBy } from "../../utils/array";
import { BehaviourOfConcernAction } from "../../entities/survey-reports/nominalTypes";

type Props = {
  participant: ParticipantStore;
};

type ViewMetrics = {
  dateRange: DateRange;
  dailyEscalation: number;
  dailyIncidents: number;
  dailySeverity: number;
  weeklyAvgSeverity: number;
  weeklyAvgIncidents: number;
  functionChartData: Array<[function: FunctionOfBehaviour, count: number]>;
  severityChartData: Array<[severity: ActionSeverity, count: number]>;
  durationChartData: Array<[duration: IncidentDuration, count: number]>;
  timeChartData: Array<[hourRange: IncidentHourRange, count: number]>;
  behaviourChartData: Array<[behaviour: BehaviourOfConcernKey, count: number]>;
  actionChartData: Array<[action: BehaviourOfConcernAction, count: number]>;

  severityDailyData: Array<[date: string, severity: ActionSeverity | "0"]>;
  frequencyDailyData: Array<[date: string, count: number]>;
};

function maxSeverityForReports(
  reports: readonly SurveyReport[],
  bocKeys: readonly BehaviourOfConcernKey[]
): ActionSeverityInt | undefined {
  if (reports.length === 0) {
    return;
  }

  return reports
    .filter(isSurveyReportBehaviourOfConcern)
    .reduce<ActionSeverityInt>((oldMax, next) => {
      const values = bocKeys.map((k) => parseInt(next[`${k}.severity`] ?? "0"));

      const nextMax = Math.max(...values) as ActionSeverityInt;
      return nextMax > oldMax ? nextMax : oldMax;
    }, 1);
}

export function reportsToCommonMetrics(
  reports: readonly SurveyReport[],
  bocKeys: readonly BehaviourOfConcernKey[]
): ViewMetrics {
  const dateRange = defaultDateRangeForReports(reports);
  const days = dateTimeUnitRange(dateRange, "day").map(dateStringFromLuxon);
  const weeks = dateTimeUnitRange(dateRange, "week");

  const bocReports = reports.filter(isSurveyReportBehaviourOfConcern);
  const reportsForDay = groupBy(reports, (r) => r.incidentDate);
  const reportsForWeek = groupBy(reports, (r) =>
    dateStringToLuxon(r.incidentDate).toFormat("yyyy-WW")
  );

  const orderedReportsByDay: Array<
    [date: string, reports: readonly SurveyReport[]]
  > = days.map((d) => [d, reportsForDay[d] ?? []]);

  const orderedReportsByWeek: Array<
    [date: string, reports: readonly SurveyReport[]]
  > = weeks.map((d) => [
    d.toFormat("yyyy-WW"),
    reportsForWeek[d.toFormat("yyyy-WW")] ?? [],
  ]);

  const dailyIncidentsAvg =
    orderedReportsByDay
      .map(
        ([_, reports]) =>
          reports.filter(isSurveyReportBehaviourOfConcern).length
      )
      .reduce((a, b) => a + b, 0) / days.length;

  const dailySeverityAvg =
    orderedReportsByDay
      .map(
        ([_, reports]) =>
          (maxSeverityForReports(reports, bocKeys) as number) ?? 0
      )
      .reduce((a, b) => a + b, 0) / days.length;

  const dailyEscalationsAvg =
    orderedReportsByDay
      .map(([_, r]) => r.filter((r) => r.reportType === "escalation").length)
      .reduce((a, b) => a + b, 0) / days.length;

  const weeklyAvgSeverity = mean(
    orderedReportsByWeek.map(
      ([_, reports]) => maxSeverityForReports(reports, bocKeys) ?? 0
    )
  );

  const weeklyAvgIncidents = mean(
    orderedReportsByWeek.map(
      ([_, r]) => r.filter(isSurveyReportBehaviourOfConcern).length
    )
  );

  const functionReportCounts = countBy(
    bocReports.map((r) => r.possibleFunctions),
    (t) => t
  );

  const functionChartData = FUNCTION_OF_BEHAVIOUR.map<
    [FunctionOfBehaviour, number]
  >((func) => [func, functionReportCounts[func]]).filter(
    ([_, count]) => count > 0
  );

  const durationChartData: Array<[IncidentDuration, number]> =
    INCIDENT_DURATION.map((duration) => [
      duration,
      bocReports.filter((r) => r.durationOfIncident === duration).length,
    ]);

  const timeChartData: Array<[IncidentHourRange, number]> =
    INCIDENT_HOUR_RANGE.map((r) => [
      r,
      bocReports.filter((s) => s.timeOfIncident === r).length,
    ]);

  const severityChartDataDict = bocReports.reduce(
    (totals, report) => {
      bocKeys.forEach((k) => {
        const severity = report[`${k}.severity`];
        if (severity) {
          totals[severity] += 1;
        }
      });

      return totals;
    },
    { "1": 0, "2": 0, "3": 0, "4": 0 } as Record<ActionSeverity, number>
  );

  const severityChartData: Array<[ActionSeverity, number]> = Object.entries(
    severityChartDataDict
  ).sort(([a], [b]) => parseInt(a) - parseInt(b));

  const highestDailySeverities = days.map<[string, ActionSeverity | "0"]>(
    (d) => {
      const reports = reportsForDay[d] ?? [];
      return [
        d,
        `${maxSeverityForReports(reports, bocKeys) ?? 0}` as
          | ActionSeverity
          | "0",
      ];
    }
  );

  const dailyFrequencySeverities = orderedReportsByDay.map<[string, number]>(
    ([date, reports]) => [date, reports.length]
  );

  const behaviourChartData = bocKeys.map(
    (k) =>
      [k, bocReports.filter((r) => r[`${k}.actions`] !== undefined).length] as [
        BehaviourOfConcernKey,
        number
      ]
  );

  const actionChartData = Object.entries(
    countBy(bocReports, (r) =>
      r.actions.map((a) => a.split(" - ")[1] as BehaviourOfConcernAction)
    )
  );

  return {
    dateRange,
    dailyIncidents: dailyIncidentsAvg,
    dailySeverity: dailySeverityAvg,
    dailyEscalation: dailyEscalationsAvg,
    weeklyAvgSeverity,
    weeklyAvgIncidents,

    frequencyDailyData: dailyFrequencySeverities,
    severityDailyData: highestDailySeverities,
    timeChartData,
    durationChartData,
    severityChartData,
    functionChartData,
    behaviourChartData,
    actionChartData,
  };
}

const ParticipantOverview = ({ participant }: Props) => {
  const viewMetrics = useMemo(
    () =>
      reportsToCommonMetrics(
        participant.reports,
        participant.behaviourOfConcernStore.keys
      ),
    [participant.reports]
  );

  return (
    <LoggedInTemplate
      breadcrumbs={["Participants", participant.nameReadable, "Overview"]}
    >
      <Divider orientation="center">Participant - Overview</Divider>
      <Row
        align="stretch"
        justify="space-between"
        gutter={[6, 6]}
        style={{ width: "100%" }}
      >
        <CommonReportMetricsCards viewMetrics={viewMetrics} />
        <Col span={8}>
          <BarChartCard
            data={viewMetrics.functionChartData}
            reportField={participant.reportFieldStore.getReportFieldOption(
              "possibleFunctions"
            )}
            title="Function of Incident"
          />
        </Col>
        <Col span={8}>
          <BarChartCard
            data={viewMetrics.severityChartData}
            reportField={participant.reportFieldStore.fieldsOnlyBoc[0]}
            title="Severity of Incident"
          />
        </Col>
        <Col span={8}>
          <BarChartCard
            data={viewMetrics.durationChartData}
            reportField={participant.reportFieldStore.getReportFieldOption(
              "durationOfIncident"
            )}
            title="Duration of Incident"
          />
        </Col>
        <Col span={8}>
          <BarChartCard
            data={viewMetrics.timeChartData}
            reportField={participant.reportFieldStore.getReportFieldOption(
              "timeOfIncident"
            )}
            title="Time of Incident"
          />
        </Col>
        <Col span={8}>
          <BarChartCard
            data={viewMetrics.behaviourChartData}
            reportField={participant.reportFieldStore.getReportFieldOption(
              "behaviours"
            )}
            title="Instances of Behaviours"
          />
        </Col>
        <Col span={8}>
          <BarChartCard
            data={viewMetrics.actionChartData}
            reportField={participant.reportFieldStore.getReportFieldOption(
              "actions"
            )}
            title="Behaviour Actions"
          />
        </Col>
        <Col span={12}>
          <LineChartCard
            data={viewMetrics.severityDailyData}
            aspectRatio={2}
            type="single"
            title="Highest Daily Severity"
            description="Highest reported severity of incident on a given day"
            reportField={participant.reportFieldStore.fieldsOnlyBoc[0]}
          />
        </Col>
        <Col span={12}>
          <LineChartCard
            data={viewMetrics.frequencyDailyData}
            aspectRatio={2}
            type="single"
            title="Frequency of Incidents"
            description="Total number of Behaviour Reports on a given day"
          />
        </Col>
      </Row>
    </LoggedInTemplate>
  );
};

export function CommonReportMetricsCards({
  viewMetrics,
}: {
  viewMetrics: ViewMetrics;
}) {
  return (
    <Flex
      justify="space-between"
      align="stretch"
      gap={6}
      style={{ width: "100%" }}
    >
      <Flex style={{ flexGrow: 1 }}>
        <NumberHighlightCard
          value={viewMetrics.dailyIncidents.toFixed(2)}
          title="Daily Behaviours of Concern"
        />
      </Flex>
      <Flex style={{ flexGrow: 1 }}>
        <NumberHighlightCard
          value={viewMetrics.dailySeverity.toFixed(2)}
          title="Daily Severity Of Behaviour"
        />
      </Flex>
      <Flex style={{ flexGrow: 1 }}>
        <NumberHighlightCard
          value={viewMetrics.dailyEscalation.toFixed(2)}
          title="Daily Escalating Behaviours"
        />
      </Flex>
      <Flex style={{ flexGrow: 1 }}>
        <NumberHighlightCard
          value={viewMetrics.weeklyAvgIncidents}
          title="Average Weekly Behaviours of Concern"
        />
      </Flex>
      <Flex style={{ flexGrow: 1 }}>
        <NumberHighlightCard
          value={viewMetrics.weeklyAvgSeverity}
          title="Average Weekly Severity Of Behaviour"
        />
      </Flex>
    </Flex>
  );
}

export default ParticipantOverview;
