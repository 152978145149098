import * as color from "@ant-design/colors";
import { assertNever } from "../../utils/assertNever";

import {
  SurveyReportCombined,
  SurveyReportField,
  SurveyReportType,
} from "./SurveyReport";

import {
  INCIDENT_HOUR_RANGE,
  getColorForIncidentHourRange,
} from "./IncidentHourRange";

import { ValueOrArrayToValueExcludeUndefined } from "../../utils/valueOrArrayToValue";
import { INCIDENT_DURATION } from "./IncidentDuration";
import { buildSorterAgainstArray } from "../../utils/sorters";
import { BehaviourOfConcernStore } from "../../store/BehaviourOfConcernStore";
import { REPORTING_TIME_HALF_HOUR } from "./ReportingTimeHalfHour";

export interface IReportFieldProvider {
  fields: ReadonlyArray<ReportFieldDefinition>;
  fieldsNoBoc: ReadonlyArray<ReportFieldDefinition>;
  getReportFieldOption(reportField: SurveyReportField): ReportFieldDefinition;
}

/**
 * Represents a "Field" or "Column" in the report table.
 * There's usually a 1 to 1 mapping of `SurveyReport` fields to `ReportFieldOption`s.
 * Also includes helper functions around how it should be rendered.
 */
export type IReportField<
  F extends SurveyReportField,
  ValueType = ReportFieldDataValue<F>
> = {
  /** Which field in the SurveyReport does this field represent in it's whole or as a derivative? */
  reportField: F;
  renderType: "report-type" | "text" | "tag";
  notQuantitative?: true;
  notFilterable?: true;

  valueToColor?: (value: ValueType) => string;
  valueToLabel?: (value: ValueType) => string;
  sortValues?: (a: ValueType, b: ValueType) => number;

  group:
    | "All Reports"
    | "Incident Reports"
    | "Escalation Reports"
    | "Behaviour Reports"
    | "Behaviours Of Concern";

  label: string;
  tableFixed?: "left" | "right";
};

export type ReportFieldDataValue<F extends SurveyReportField> =
  ValueOrArrayToValueExcludeUndefined<SurveyReportCombined[F]>;

export type ReportFieldDefinition = {
  [K in SurveyReportField]: IReportField<K>;
}[SurveyReportField];

export const REPORT_FIELD_OPTIONS_BASE: Array<ReportFieldDefinition> = [
  {
    label: "Report Type",
    reportField: "reportType",
    renderType: "report-type",
    group: "All Reports",
    valueToLabel: (value: SurveyReportType) => {
      switch (value) {
        case "no-boc":
          return "No Incident";
        case "escalation":
          return "Escalation";
        case "boc":
          return "Behaviour of Concern";
        default:
          assertNever(value);
      }
    },
    valueToColor: (value: SurveyReportType) => {
      switch (value) {
        case "no-boc":
          return color.green.primary!;
        case "escalation":
          return color.yellow.primary!;
        case "boc":
          return color.red.primary!;
        default:
          assertNever(value);
      }
    },
    sortValues: buildSorterAgainstArray(["no-boc", "escalation", "boc"]),
    tableFixed: "left",
  },
  {
    label: "Form Response ID",
    group: "All Reports",
    reportField: "formResponseId",
    renderType: "text",
    notQuantitative: true,
  },
  {
    label: "Date",
    reportField: "incidentDate",
    renderType: "text",
    tableFixed: "left",
    group: "All Reports",
    notFilterable: true,
  },
  {
    label: "Responder Name",
    reportField: "nameOfPersonCompleting",
    renderType: "text",
    tableFixed: "left",
    group: "All Reports",
    notQuantitative: true,
  },
  {
    label: "Start Time with Client",
    reportField: "startTimeWithClient",
    renderType: "text",
    group: "All Reports",
    notQuantitative: true,
    sortValues: buildSorterAgainstArray(REPORTING_TIME_HALF_HOUR),
  },
  {
    label: "End Time with Client",
    reportField: "endTimeWithClient",
    renderType: "text",
    group: "All Reports",
    notQuantitative: true,
    sortValues: buildSorterAgainstArray(REPORTING_TIME_HALF_HOUR),
  },

  {
    label: "Time of Incident",
    reportField: "timeOfIncident",
    renderType: "text",
    tableFixed: "left",
    group: "Incident Reports",
    valueToColor: (value) => getColorForIncidentHourRange(value),
    sortValues: buildSorterAgainstArray(INCIDENT_HOUR_RANGE),
  },
  {
    label: "Incident Duration",
    reportField: "durationOfIncident",
    renderType: "text",
    group: "Incident Reports",
    sortValues: buildSorterAgainstArray(INCIDENT_DURATION),
  },
  {
    label: "Location",
    reportField: "location",
    renderType: "text",
    group: "Incident Reports",
  },
  {
    label: "Settings",
    reportField: "settingEvents",
    renderType: "tag",
    group: "Incident Reports",
  },
  {
    label: "Triggers",
    reportField: "triggers",
    renderType: "tag",
    group: "Incident Reports",
  },
  {
    label: "Behaviours",
    reportField: "behaviours",
    renderType: "tag",
    group: "Incident Reports",
    valueToLabel: BehaviourOfConcernStore.keyToLabel,
  },
  {
    label: "Preventative Strategies Used",
    reportField: "preventativeStrategiesUsed",
    renderType: "tag",
    group: "Incident Reports",
  },
  {
    label: "Reactive Strategies Used",
    reportField: "reactiveStrategiesUsed",
    renderType: "tag",
    group: "Incident Reports",
  },
  {
    label: "Replacement Behaviours Used",
    reportField: "replacementBehaviours",
    renderType: "tag",
    group: "Incident Reports",
  },

  {
    label: "Escalation Signs",
    reportField: "signsObserved",
    renderType: "tag",
    group: "Escalation Reports",
  },

  // {
  //   label: "Crisis Management Strategies Used",
  //   reportField: "crisisManagementStrategiesUsed",
  //   renderType: "tag",
  //   group: "Behaviour Reports",
  // },

  {
    label: "People Impacted",
    reportField: "personsImpacted",
    renderType: "tag",
    group: "Behaviour Reports",
  },
  {
    label: "Actions",
    reportField: "actions",
    renderType: "tag",
    group: "Behaviour Reports",
  },
  {
    label: "Possible Function",
    reportField: "possibleFunctions",
    renderType: "tag",
    group: "Behaviour Reports",
  },
  {
    label: "Results",
    reportField: "resultOfBehaviour",
    renderType: "text",
    group: "Behaviour Reports",
    notQuantitative: true,
    notFilterable: true,
    tableFixed: "right",
  },
];
