import { Form, Select, SelectProps, Space, Typography } from "antd";
import { BarChartOutlined, PieChartOutlined } from "@ant-design/icons";
import { ChartType } from "../../entities/chart/ChartType";

const CHART_OPTIONS: SelectProps["options"] = [
  {
    value: "bar-horizontal",
    label: (
      <Space>
        <span style={{ transform: "rotate(0.25)" }}>
          <BarChartOutlined />
        </span>
        Bar (Horizontal)
      </Space>
    ),
  },
  {
    value: "bar-vertical",
    label: (
      <Space>
        <BarChartOutlined />
        <div>Bar (Vertical)</div>
      </Space>
    ),
  },
  {
    value: "pie",
    label: (
      <Space>
        <PieChartOutlined />
        <div>Pie</div>
      </Space>
    ),
  },
  {
    value: "bar-stacked-day",
    label: (
      <Space>
        <BarChartOutlined />
        <div>Stacked Bar (By Day)</div>
      </Space>
    ),
  },
  {
    value: "bar-stacked-week",
    label: (
      <Space>
        <BarChartOutlined />
        <div>Stacked Bar (By Week)</div>
      </Space>
    ),
  },
  {
    value: "bar-stacked-month",
    label: (
      <Space>
        <BarChartOutlined />
        <div>Stacked Bar (By Month)</div>
      </Space>
    ),
  },
];
export default function ChartTypeSelector(props: {
  value: ChartType;
  setChartType: (chartType: ChartType) => void;
}) {
  return (
    <Form.Item
      label={<Typography.Text strong>Chart Type</Typography.Text>}
      style={{ width: "100%", marginBottom: "7px" }}
    >
      <Select
        style={{ width: "100%" }}
        options={CHART_OPTIONS}
        value={props.value}
        onChange={(v) => props.setChartType(v as ChartType)}
      />
    </Form.Item>
  );
}
