import { Image, Row, Col } from "antd";
import logo from "../../fabdacs.png";

const FabdacsLogo = (props: { height?: string }) => {
  return (
    <Row gutter={12} justify="start">
      <Col>
        <Image src={logo} height={40} width={126} preview={false} />
      </Col>
      {/* <Col style={{ marginTop: "2px" }}>
        <Typography.Text strong>FABDACS</Typography.Text>
      </Col> */}
    </Row>
  );
};

export default FabdacsLogo;
