export type IncidentDuration = (typeof INCIDENT_DURATION)[number];

export const INCIDENT_DURATION = [
  "less than 1 minute",
  "1 - 5 minutes",
  "5 - 10 minutes",
  "10 - 15 minutes",
  "15 - 20 minutes",
  "25 - 30 minutes",
  "30 - 1 hour",
  "1 hour+",
] as const;
