export type IncidentHourRange = (typeof INCIDENT_HOUR_RANGE)[number];

export const INCIDENT_HOUR_RANGE = [
  "12am - 1am",
  "1am - 2am",
  "2am - 3am",
  "3am - 4am",
  "4am - 5am",
  "5am - 6am",
  "6am - 7am",
  "7am - 8am",
  "8am - 9am",
  "9am - 10am",
  "10am - 11am",
  "11am - 12pm",
  "12pm - 1pm",
  "1pm - 2pm",
  "2pm - 3pm",
  "3pm - 4pm",
  "4pm - 5pm",
  "5pm - 6pm",
  "6pm - 7pm",
  "7pm - 8pm",
  "8pm - 9pm",
  "9pm - 10pm",
  "10pm - 11pm",
  "11pm - 12am",
] as const;

export function isIncidentHourRange(str: string): str is IncidentHourRange {
  return INCIDENT_HOUR_RANGE.includes(str as IncidentHourRange);
}

export function getHourFromIncidentHourRange(range: IncidentHourRange): number {
  const hourString = range.split(" ")[0];
  const hour = parseInt(hourString);

  if (hourString === "12pm") {
    return 12;
  } else if (hourString === "12am") {
    return 0;
  } else {
    return hourString.endsWith("pm") ? hour + 12 : hour;
  }
}

export function getColorForIncidentHourRange(hour: IncidentHourRange) {
  const hourInt = getHourFromIncidentHourRange(hour);
  if (hourInt < 0 || hourInt > 23) {
    throw new Error(`Invalid hour of day ${hourInt} - ${hour}`);
  }

  const maxBrightness = 255;
  const minBrightness = 50;
  const brightness =
    hourInt < 12
      ? minBrightness + (hourInt / 12) * (maxBrightness - minBrightness)
      : maxBrightness - ((hourInt - 12) / 12) * (maxBrightness - minBrightness);
  const hexBrightness = Math.round(brightness).toString(16).padStart(2, "0");
  const hexColor = `#${hexBrightness.repeat(2)}00`;

  return hexColor;
}
