import * as XLSX from "xlsx";

import {
  SurveyReport,
  SurveyReportNoBehaviour,
  SurveyReportEscalationNoBehaviour,
  SurveyReportBehaviourOfConcern,
} from "./SurveyReport";

import { DateTime } from "luxon";
import { ISO8601DateTimeString } from "../time/ISO8601String";
import { dateStringFromISO } from "../time/DateString";
import {
  BehaviourOfConcernAction,
  BehaviourOfConcernActionNew,
  EscalationSign,
  PreventativeStrategy,
  PreventativeStrategyNew,
  ReactiveStrategy,
  ReactiveStrategyNew,
  ReportLocation,
  SettingEvent,
  SettingEventNew,
  Trigger,
  TriggerNew,
} from "./nominalTypes";
import { IncidentHourRange } from "./IncidentHourRange";
import { BehaviourOfConcernActionShort } from "../behaviour-report/BehaviourOfConcern";
import {
  ActionSeverity,
  isActionSeverity,
} from "../behaviour-report/ActionSeverity";
import { IncidentDuration } from "./IncidentDuration";
import { BehaviourOfConcernStore } from "../../store/BehaviourOfConcernStore";
import { ReportingTimeHalfHour } from "./ReportingTimeHalfHour";
import { ImportColumns } from "./SurveyReportImport.columns";
import { CorrectionList } from "./Correction";

// A list of values that are considered "none" values, they should be ignored when creating value lists
const NONE_VALUES = [
  "no replacement behaviour observed",
  "no replacement behaviour used",
  "no reactive strategy required",
  "stage 1 and 2 strategy was successful and behaviour ceased",
];

// A list of values that are considered "other" values, they should be ignored when creating value lists and imply a "new" question that follows should be read
const OTHER_OR_NEW_VALUES = [
  "none of the above and/or new setting event(s) identified",
  "none of the above and/or new trigger(s) identified",
  "none of the above and/or new strategy identified",
  "new behaviour of concern not listed above",
  "new behaviour of concern or new action observed",
];

function mapSeverityToActionSeverity(
  severity: string
): ActionSeverity | undefined {
  // "Severity 1..."
  const trimmed = severity.trim().substring(9, 10);

  if (!isActionSeverity(trimmed)) {
    throw new Error(`Unknown severity: ${trimmed}, from: ${severity}`);
  }

  return trimmed;
}

/**
 * Remove non-breaking spaces and other weird characters
 */
function cleanString(str: string): string {
  return str
    .replaceAll(/\u00A0/g, " ")
    .replaceAll("–", "-")
    .trim();
}

function findIndexes<T>(arr: T[], fn: (e: T) => boolean): number[] {
  const indexes: number[] = [];

  for (const [i, str] of arr.entries()) {
    if (fn(str)) indexes.push(i);
  }

  return indexes;
}

export default async function loadExcelBuffer(
  buffer: ArrayBuffer,
  corrections?: CorrectionList
): Promise<SurveyReport[]> {
  const workbook = XLSX.read(buffer, {
    type: "buffer",
    cellDates: true,
    dense: true,
  });
  if (workbook.SheetNames.length === 0) {
    throw new Error("No worksheet found");
  }

  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const worksheetRaw = XLSX.utils.sheet_to_json<(string | Date | undefined)[]>(
    worksheet,
    {
      header: 1,
    }
  );

  const columnsRaw = worksheetRaw[0].map((v) =>
    v ? cleanString(v as string) : v
  );

  const reports: SurveyReport[] = [];

  console.log(`Found ${worksheetRaw.length} rows`);

  worksheetRaw.forEach((row, i) => {
    if (i < 1 || row.length === 0) return;
    const rowId = `${row[0]}`;
    const rowCorrections = corrections
      ?.filter((r) => r.row_id === rowId)
      .map((r) => ({
        ...r,
        column: cleanString(r.column),
        new_value: cleanString(r.new_value),
      }));
    const formStartTime = DateTime.fromJSDate(row[2] as Date);
    const dateLimit = formStartTime.endOf("day");

    const getRawCell = (
      expectedColumns: string[],
      /** This index is used when there are multiple columns with the same name, starting at 1 */
      index: number = 1
    ): { correction: boolean; value: string | Date | undefined } => {
      expectedColumns = expectedColumns.map(cleanString);

      if (rowCorrections) {
        for (const expectedColumn of expectedColumns) {
          const correction = rowCorrections.find(
            (r) =>
              r.column.toLowerCase().startsWith(expectedColumn.toLowerCase()) &&
              (r.index ?? 1 === index)
          );
          if (correction) {
            return { correction: true, value: correction.new_value };
          }
        }
      }

      const columnIndexes = findIndexes(
        columnsRaw,
        (rawCol) =>
          rawCol !== undefined &&
          // Check if the raw column matches any of the expected columns
          expectedColumns.some((expectedColumn) =>
            (rawCol.toLowerCase() as string).startsWith(
              expectedColumn.toLowerCase()
            )
          )
      );

      if (columnIndexes.length < index - 1) {
        throw new Error(`Column "${expectedColumns}" ${index} not found`);
      }

      const columnIndex = columnIndexes[index - 1];
      return { correction: false, value: row[columnIndex] };
    };

    const getColumnValue = <T extends string>(
      columns: string[],
      /** This index is used when there are multiple columns with the same name, starting at 1 */
      index: number = 1
    ): T | undefined => {
      const raw = getRawCell(columns, index).value;

      if (typeof raw === "string" && raw.trim().length > 0) {
        return cleanString(raw) as T;
      } else if (typeof raw === "number") {
        return `${raw}`;
      }

      return undefined;
    };

    const getColumnDateTimeValueRaw = (
      columns: string[],
      /** This index is used when there are multiple columns with the same name, starting at 1 */
      index: number = 1
    ) => {
      const raw = getRawCell(columns, index);
      if (!raw.value) {
        throw new Error(`Missing date value for columns ${columns}, R: ${i}`);
      }

      let date: DateTime;
      if (typeof raw.value === "string") {
        date = DateTime.fromSQL(raw.value);
      } else {
        date = DateTime.fromJSDate(raw.value);
      }

      return { correction: raw.correction, date };
    };

    const getColumnDateTimeValue = (
      columns: string[],
      /** This index is used when there are multiple columns with the same name, starting at 1 */
      index: number = 1
    ) => {
      return getColumnDateTimeValueRaw(
        columns,
        index
      ).date.toISO() as ISO8601DateTimeString;
    };

    const getColumnDateTimeValueClamped = (
      columns: string[],
      /** This index is used when there are multiple columns with the same name, starting at 1 */
      index: number = 1
    ) => {
      const date = getColumnDateTimeValueRaw(columns, index);
      if (date.correction) {
        return date.date.toISO() as ISO8601DateTimeString;
      }

      const diffDays = date.date.diff(dateLimit).as("days");
      if (diffDays > 0) {
        return dateLimit.toISO() as ISO8601DateTimeString;
      } else if (diffDays < -62) {
        // Two Months prior
        return dateLimit.toISO() as ISO8601DateTimeString;
      } else {
        return date.date.toISO() as ISO8601DateTimeString;
      }
    };

    const getColumnListValue = <T>(
      columns: string[],
      /** This index is used when there are multiple columns with the same name, starting at 1 */
      index: number = 1
    ): T[] => {
      const raw = getRawCell(columns, index).value as string | undefined;
      if (!raw) {
        return [];
      }

      const values = raw
        .split(";")
        .map((s) => cleanString(s))
        .filter((s) => (s as string).length > 0)
        .filter((s) => !NONE_VALUES.includes(s.toLowerCase()))
        .filter((s) => !OTHER_OR_NEW_VALUES.includes(s.toLowerCase()));

      return values.filter((s) => !OTHER_OR_NEW_VALUES.includes(s)) as T[];
    };

    const getColumnListValueWithOther = <T, O extends string>(
      columns: string[],
      otherColumns: string[],
      /** This index is used when there are multiple columns with the same name, starting at 1 */
      index: number = 1
    ): [T[], O | undefined] => {
      const values = getColumnListValue<T>(columns, index);
      const otherValue = getColumnValue<O>(otherColumns, index);
      return [values, otherValue];
    };

    const getLocation = (index: number): ReportLocation => {
      const locationCell = getColumnValue(
        ImportColumns.LOCATION,
        index
      ) as ReportLocation;
      if (locationCell !== "Community") {
        return locationCell;
      }

      return getColumnValue<ReportLocation>(
        ImportColumns.LOCATION_COMMUNITY,
        index
      )!;
    };

    if (getRawCell(["ID"]) === undefined) {
      console.log("Skipping empty row");
      return;
    }

    const reportBase: SurveyReportNoBehaviour = {
      reportType: "no-boc",
      formResponseId: getColumnValue(["ID"])!,

      formStartTime: formStartTime.toISO() as ISO8601DateTimeString,
      formCompletionTime: getColumnDateTimeValue(["Completion time"]),
      nameOfPersonCompleting: getColumnValue([
        "Person Completing the Form",
      ]) as string,
      incidentDate: dateStringFromISO(
        getColumnDateTimeValueClamped(ImportColumns.DATE_OF_INCIDENT, 1)
      ),

      startTimeWithClient: getColumnValue<ReportingTimeHalfHour>(
        ImportColumns.START_TIME
      )!,
      endTimeWithClient: getColumnValue<ReportingTimeHalfHour>(
        ImportColumns.END_TIME
      )!,

      // This is filled in later
      replacementBehaviours: [],
    };

    const reportingReqs = getColumnListValue(["Reporting Requirements"]);

    for (const reportingReq of reportingReqs) {
      if (reportingReq === "No Behaviour of Concern Observed") {
        const report: SurveyReportNoBehaviour = { ...reportBase };
        reports.push(report);
        report.replacementBehaviours = getColumnListValue(
          ImportColumns.REPLACEMENT_BEHAVIOUR
        );
      } else if (
        reportingReq === "Escalation of Behaviour" ||
        reportingReq === "Escalation Behaviour"
      ) {
        const timeOfIncident = getColumnValue<string>(
          ImportColumns.TIME_OF_INCIDENT
        )!.replace("12noon", "12pm") as IncidentHourRange;

        const durationOfIncident = getColumnValue<IncidentDuration>(
          ImportColumns.DURATION_OF_INCIDENT
        )!;

        const [settingEvents, settingEventsNew] = getColumnListValueWithOther<
          SettingEvent,
          SettingEventNew
        >(ImportColumns.SETTING_EVENTS, ImportColumns.SETTING_EVENTS_NEW);

        const [triggers, triggersNew] = getColumnListValueWithOther<
          Trigger,
          TriggerNew
        >(ImportColumns.TRIGGERS, ImportColumns.TRIGGERS_NEW);

        const [preventativeStrategies, preventativeStrategiesNew] =
          getColumnListValueWithOther<
            PreventativeStrategy,
            PreventativeStrategyNew
          >(
            ImportColumns.PREVENTATIVE_STRATEGIES,
            ImportColumns.PREVENTATIVE_STRATEGIES_NEW
          );

        const report: SurveyReportEscalationNoBehaviour = {
          ...reportBase,
          reportType: "escalation",
          timeOfIncident,
          durationOfIncident,
          settingEvents,
          triggers,

          location: getLocation(1),
          possibleFunctions: getColumnListValue(
            ImportColumns.POSSIBLE_FUNCTION,
            1
          ),
          personsImpacted: getColumnListValue(ImportColumns.PEOPLE_IMPACTED, 1),

          signsObserved: getColumnListValue<EscalationSign>(["Signs observed"]),
          preventativeStrategiesUsed: preventativeStrategies,
          reactiveStrategiesUsed: [],

          settingEventsNew,
          triggersNew,
          preventativeStrategiesNew,
        };

        report.replacementBehaviours = getColumnListValue(
          ImportColumns.REPLACEMENT_BEHAVIOUR,
          2
        );

        reports.push(report);
      } else if (reportingReq === "Behaviour of Concern(s) to report") {
        const createReport = (
          index: 1 | 2 | 3 = 1
        ): SurveyReportBehaviourOfConcern => {
          const timeOfIncident = getColumnValue<string>(
            ImportColumns.TIME_OF_INCIDENT,
            index + 1
          )!.replace("12noon", "12pm") as IncidentHourRange;

          const durationOfIncident = getColumnValue<IncidentDuration>(
            ImportColumns.DURATION_OF_INCIDENT,
            index + 1
          )!;

          const [settingEvents, settingEventsNew] = getColumnListValueWithOther<
            SettingEvent,
            SettingEventNew
          >(
            ImportColumns.SETTING_EVENTS,
            ["Please specify New Setting event(s)"],
            index + 1
          );

          const [triggers, triggersNew] = getColumnListValueWithOther<
            Trigger,
            TriggerNew
          >(ImportColumns.TRIGGERS, ImportColumns.TRIGGERS_NEW, index + 1);

          const [preventativeStrategiesUsed, preventativeStrategiesNew] =
            getColumnListValueWithOther<
              PreventativeStrategy,
              PreventativeStrategyNew
            >(
              ImportColumns.PREVENTATIVE_STRATEGIES,
              ImportColumns.PREVENTATIVE_STRATEGIES_NEW,
              index + 1
            );

          const [reactiveStrategiesUsed, reactiveStrategiesNew] =
            getColumnListValueWithOther<ReactiveStrategy, ReactiveStrategyNew>(
              ImportColumns.REACTIVE_STRATEGIES,
              ImportColumns.REACTIVE_STRATEGIES_NEW,
              index
            );

          const [behaviours, behaviourActionsNew] = getColumnListValueWithOther<
            string,
            BehaviourOfConcernActionNew
          >(
            ImportColumns.BEHAVIOUR_OF_CONCERN,
            ImportColumns.BEHAVIOUR_OF_CONCERN_ACTION_NEW,
            index
          );

          const report: SurveyReportBehaviourOfConcern = {
            ...reportBase,
            reportType: "boc",
            timeOfIncident,
            durationOfIncident,
            location: getLocation(index + 1),
            settingEvents,
            triggers,
            resultOfBehaviour: getColumnValue(["Results"], index)!,
            personsImpacted: getColumnListValue(
              ImportColumns.PEOPLE_IMPACTED,
              index + 1
            ),
            possibleFunctions: getColumnListValue(
              ImportColumns.POSSIBLE_FUNCTION,
              index + 1
            ),

            preventativeStrategiesUsed,
            reactiveStrategiesUsed,

            settingEventsNew,
            triggersNew,
            preventativeStrategiesNew,
            reactiveStrategiesNew,
            behaviourActionsNew,

            behaviours: [],
            actions: [],
          };

          report.replacementBehaviours = getColumnListValue(
            ImportColumns.REPLACEMENT_BEHAVIOUR,
            index + 2
          );

          for (const behaviourLabel of behaviours) {
            if (behaviourLabel.startsWith("New")) {
              console.log(`Skipping new behaviour '${behaviourLabel}'`);
              continue;
            }
            const labels = [cleanString(behaviourLabel)];
            const isOther = behaviourLabel.startsWith("Other");
            if (isOther) {
              labels.push(cleanString(behaviourLabel.substring(8)));
            }
            const actions = getColumnListValue<BehaviourOfConcernAction>(
              labels.flatMap((l) => [`Actions - ${l}`, l]),
              index
            );
            const severityColumn = getColumnValue<string>(
              labels.flatMap((l) => `Severity of Action - ${l}`),
              index
            );

            if (actions.length === 0) {
              continue;
              // throw new Error(
              //   `Missing actions value for behaviour ${behaviourLabel}-${index}, Row: ${i}`
              // );
            }

            if (severityColumn === undefined) {
              continue;
              // throw new Error(
              //   `Missing severity value for behaviour ${behaviourLabel}-${index}, Row: ${i}`
              // );
            }

            const key = BehaviourOfConcernStore.labelToKey(behaviourLabel);

            report[`${key}.actions`] = actions;
            report[`${key}.severity`] =
              mapSeverityToActionSeverity(severityColumn)!;

            report.behaviours.push(key);

            const shortStrings = actions.map(
              (a) => `${severityColumn} - ${a}` as BehaviourOfConcernActionShort
            );
            report.actions.push(...shortStrings);
          }

          return report;
        };

        reports.push(createReport(1));

        // Handle second & third behaviours
        if (getColumnValue(ImportColumns.MORE_REPORTS) === "Yes") {
          reports.push(createReport(2));

          if (getColumnValue(ImportColumns.MORE_REPORTS, 2) === "Yes") {
            reports.push(createReport(3));
          }
        }
      } else {
        throw new Error(`Unknown reporting requirement: ${reportingReq}`);
      }
    }
  });

  console.log(`Read ${reports.length} reports`);

  return reports;
}
