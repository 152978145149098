import { makeAutoObservable } from "mobx";
import { ChartConfig, ChartKey } from "../entities/chart/ChartConfig";
import {
  ChartGroup,
  ChartGroupKey,
  IChartGroupProvider,
} from "../entities/chart/ChartGroup";
import { v4 } from "uuid";

export class ChartConfigStore implements IChartGroupProvider {
  chartGroups: Array<ChartGroup> = [];
  nextIndex = 1;

  constructor() {
    this.addChartGroup("Group 1");
    makeAutoObservable(this);
  }

  get chartGroupKeysWithLabel(): [ChartGroupKey, string][] {
    return this.chartGroups.map((g) => [g.key, g.title]);
  }

  addChartGroup(title?: string) {
    if (title === undefined) {
      title = `Group ${this.nextIndex}`;
    }

    this.chartGroups.push({
      key: v4() as ChartGroupKey,
      title,
      charts: [],
    });

    this.nextIndex += 1;
  }

  renameChartGroup(groupKey: string, newTitle: string) {
    const group = this.chartGroups.find((g) => g.key === groupKey);
    if (group === undefined) {
      return;
    }

    group.title = newTitle;
  }

  removeChartGroup(groupKey: string) {
    if (this.chartGroups.length === 1) {
      return;
    }

    this.chartGroups = this.chartGroups.filter((g) => g.key !== groupKey);
  }

  addChartToGroup(groupKey: string, chart: ChartConfig) {
    const group = this.chartGroups.find((g) => g.key === groupKey);
    if (group === undefined) {
      return;
    }

    const existingChart = group.charts.find((c) => c.key === chart.key);
    if (existingChart !== undefined) {
      return;
    }

    group.charts.push(chart);
  }

  removeChartFromGroup(groupKey: string, chartKey: ChartKey) {
    const group = this.chartGroups.find((g) => g.key === groupKey);
    if (group === undefined) {
      return;
    }

    group.charts = group.charts.filter((c) => c.key !== chartKey);
  }

  groupLength(groupKey: string): number {
    return this.chartGroups.find((g) => g.key === groupKey)?.charts.length ?? 0;
  }
}
