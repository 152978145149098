import { useCallback, useState } from "react";
import { Button, Flex, Form, Input, Modal, Table, Typography } from "antd";
import {
  BackendApiParticipantCorrection,
  useBackendApi,
} from "../../api/backend";
import { ColumnsType } from "antd/es/table";
import { useForm } from "antd/es/form/Form";
import { v4 as uuidv4 } from "uuid";

export const ParticipantCorrectionsTable = (props: {
  practitionerId: string;
  participantName: string;
  onFinish: () => void;
}) => {
  const { loadCorrectionsForParticipant, updateCorrectionsForParticipant } =
    useBackendApi();
  const [correctionForm] = useForm<BackendApiParticipantCorrection>();
  const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false);

  const correctionsQuery = loadCorrectionsForParticipant(
    props.participantName,
    props.practitionerId
  );

  const updateCorrectionsMutation = updateCorrectionsForParticipant();

  const [removedCorrections, setRemovedCorrections] = useState<string[]>([]);
  const [uncommitedCorrections, setUncommitedCorrections] = useState<
    BackendApiParticipantCorrection[]
  >([]);

  const uncommitedCorrectionIds = uncommitedCorrections.map(
    (c) => c.correction_id
  );

  const tableData: BackendApiParticipantCorrection[] = [
    ...(correctionsQuery.data ?? []).filter(
      (c) => !uncommitedCorrectionIds.includes(c.correction_id)
    ),
    ...uncommitedCorrections,
  ].filter((c) => !removedCorrections.includes(c.correction_id));

  const onDelete = useCallback(
    (correctionId: string) => {
      setRemovedCorrections([...removedCorrections, correctionId]);
    },
    [removedCorrections]
  );

  const onEdit = useCallback(
    (correctionId: string) => {
      if (correctionsQuery.status !== "success") {
        return;
      }

      const existingValues = tableData.find(
        (c) => c.correction_id === correctionId
      );

      if (existingValues) {
        correctionForm.setFieldsValue(existingValues);
        setIsCorrectionModalOpen(true);
      }
    },
    [
      correctionForm,
      correctionsQuery,
      uncommitedCorrections,
      setIsCorrectionModalOpen,
    ]
  );

  const onFinish = useCallback(
    (values: BackendApiParticipantCorrection) => {
      const newCorrections = [
        ...uncommitedCorrections.filter(
          (c) => c.correction_id !== values.correction_id
        ),
        values,
      ];
      setUncommitedCorrections(newCorrections);
      correctionForm.resetFields();
      setIsCorrectionModalOpen(false);
    },
    [uncommitedCorrections, correctionForm, setIsCorrectionModalOpen]
  );

  const onSubmit = useCallback(async () => {
    await updateCorrectionsMutation.mutateAsync({
      corrections: tableData,
      participantName: props.participantName,
      practitionerId: props.practitionerId,
    });
    props.onFinish();
  }, [props.onFinish, updateCorrectionsMutation]);

  const columns: ColumnsType<BackendApiParticipantCorrection> = [
    {
      dataIndex: "row_id",
      key: "row_id",
      title: "Row ID",
    },
    {
      dataIndex: "column",
      key: "column",
      title: "Column",
    },
    {
      dataIndex: "index",
      key: "index",
      title: "Index?",
    },
    {
      dataIndex: "new_value",
      key: "new_value",
      title: "New Value",
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_, record) => (
        <Button.Group>
          <Button onClick={() => onEdit(record.correction_id)}>Edit</Button>
          <Button
            danger
            type="default"
            onClick={() => onDelete(record.correction_id)}
          >
            Delete
          </Button>
        </Button.Group>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={tableData}
        columns={columns}
        loading={correctionsQuery.status !== "success"}
        scroll={{ x: true }}
        bordered
        title={() => (
          <Flex justify="space-between" align="middle">
            <Typography.Text strong>
              Participant Report Corrections
            </Typography.Text>
            <Button.Group>
              <Button onClick={() => setIsCorrectionModalOpen(true)}>
                New Correction
              </Button>
            </Button.Group>
          </Flex>
        )}
      />
      <Button type="primary" onClick={onSubmit}>
        Update Corrections
      </Button>
      <Modal
        title="Add/Update Correction"
        open={isCorrectionModalOpen}
        onCancel={() => setIsCorrectionModalOpen(false)}
        footer={<></>}
      >
        <Form<BackendApiParticipantCorrection>
          form={correctionForm}
          onFinish={onFinish}
          initialValues={{ correction_id: uuidv4() }}
        >
          <Form.Item name="correction_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="row_id" label="Row ID">
            <Input />
          </Form.Item>
          <Form.Item name="column" label="Column">
            <Input />
          </Form.Item>
          <Form.Item name="index" label="Index?">
            <Input placeholder="If Blank - Treated as '1' or 'first' column instance" />
          </Form.Item>
          <Form.Item name="new_value" label="New Value">
            <Input placeholder="YYYY-MM-DD or Text" />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={updateCorrectionsMutation.isPending}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};
