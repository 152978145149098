import { Pie } from "react-chartjs-2";
import {
  getChartDataSingle,
  getChartDataSingleFromReportField,
} from "../../../ui/useChartData";
import { BaseChartProps, ChartCardWrapper } from "./ChartBase";
import { memo, useMemo } from "react";

type Props = BaseChartProps & { data: Array<[string, number]> };

export default memo(function PieChartCard(props: Props) {
  const chartData = useMemo(() => {
    if (props.reportField !== undefined) {
      return getChartDataSingleFromReportField(
        props.data,
        "pie",
        props.reportField
      );
    } else {
      return getChartDataSingle(props.data, "pie");
    }
  }, [props.reportField, props.data]);

  return (
    <ChartCardWrapper {...props}>
      <Pie
        data={chartData}
        redraw
        options={{
          responsive: true,
          aspectRatio: props.aspectRatio ?? 2,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />
    </ChartCardWrapper>
  );
});
