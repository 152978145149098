import { makeAutoObservable } from "mobx";
import { AnnotationKey } from "../entities/annotation";

export class AnnotationsStore {
  private readonly _annotations: Partial<Record<AnnotationKey, string>> = {};

  constructor() {
    makeAutoObservable(this);
  }

  getAnnotation(key: AnnotationKey) {
    return this._annotations[key];
  }

  updateAnnotation(key: AnnotationKey, annotation: string | undefined) {
    if (annotation) {
      this._annotations[key] = annotation;
    } else {
      delete this._annotations[key];
    }
  }
}
