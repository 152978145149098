export const ImportColumns = {
  START_TIME: [
    "Start time with the person you are reporting on",
    "Start time when with the person you are reporting on",
  ],
  END_TIME: [
    "End time with the person you are reporting on",
    "End time when with the person you are reporting on",
  ],

  DATE_OF_INCIDENT: [
    "Date of reporting",
    "Date of support provided to the individual",
  ],

  TIME_OF_INCIDENT: [
    "Time/s of Observed Escalation",
    "Time of Observed Escalation",
    "Time of Observed Incident",
  ],
  DURATION_OF_INCIDENT: [
    "Duration of Escalation (Duration of occurrence)",
    "Duration of Action (Duration of occurrence)",
  ],

  LOCATION: ["Location of Behaviour"],
  LOCATION_COMMUNITY: ["Please specify community setting"],

  POSSIBLE_FUNCTION: ["Possible Function"],
  PEOPLE_IMPACTED: ["Person/s Impacted"],

  REPLACEMENT_BEHAVIOUR: [
    "Was a replacement behaviour used by the participant? (Outlined in PBSP)",
  ],

  BEHAVIOUR_OF_CONCERN: ["Behaviour(s) of Concern"],
  BEHAVIOUR_OF_CONCERN_ACTION_NEW: [
    "Please identify any other Action(s) exhibited that has not been identified",
  ],

  SETTING_EVENTS: ["Setting Events"],
  SETTING_EVENTS_NEW: ["Please specify new Setting Event(s)"],

  TRIGGERS: ["Triggers"],
  TRIGGERS_NEW: [
    "Please specificy New Trigger(s)",
    "Please specifiy New Trigger(s)",
    "Please specify New Trigger(s)",
  ],

  PREVENTATIVE_STRATEGIES: ["Preventative and Positive Strategies used"],
  PREVENTATIVE_STRATEGIES_NEW: [
    "Please specify New Preventative Strategy/ies used",
    "Please specify New Preventative and Escalation Strategies used",
  ],

  REACTIVE_STRATEGIES: ["Reactive Strategies Used"],
  REACTIVE_STRATEGIES_NEW: [
    "Please specify New Crisis Management Strategy/ies used",
    "Please specify New Reactive Strategy/ies used",
  ],

  MORE_REPORTS: ["Do you need to report another Behaviour of Concern(s)?"],
};
