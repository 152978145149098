import { useAuth0 } from "@auth0/auth0-react";
import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { API_URL } from "../FabdacsApp";
import { useMemo } from "react";
import { SurveyReport } from "../entities/survey-reports/SurveyReport";

export type BackendApiPractitionerList = {
  name: string;
  user_id: string;
}[];

export type BackendApiCreatePractitonerReq = {
  given_name: string;
  family_name: string;
  email: string;
};

export type BackendApiUploadReportsReq = {
  practitionerId: string;
  participantName: string;
  reports: readonly SurveyReport[];
};

export type BackendApiLoadParticipantReq = {
  practitionerId: string;
};

export type BackendApiLoadParticipantRes = {
  reports: readonly SurveyReport[];
};

export type BackendApiCreateImportReq = {
  user_id: string;
  survey_id: string;
  participant_name: string;
};

export type BackendApiAutoImportConfig = {
  user_id: string;
  form_id: string;
  participant_name: string;
  disabled?: boolean;
};

export type BackendApiToggleImportReq = {
  enabled: boolean;
  practitioner_id: string;
  participant_name: string;
};

export type BackendApiAutoImportDeleteReq = {
  user_id: string;
  participant_name: string;
};

export type BackendApiAutoImportConfigList = Array<BackendApiAutoImportConfig>;

export type BackendApiParticipantCorrection = {
  correction_id: string;
  row_id: string;
  column: string;
  new_value: string;
  index?: number;
};

export type BackendApiParticipantCorrectionUpdateReq = {
  practitionerId: string;
  participantName: string;
  corrections: readonly BackendApiParticipantCorrection[];
};

export interface BackendApi {
  triggerImport(): UseMutationResult<void, unknown, void>;

  createPractitioner(): UseMutationResult<
    void,
    unknown,
    BackendApiCreatePractitonerReq
  >;
  listPractitioners(): UseQueryResult<BackendApiPractitionerList>;
  resendPasswordEmail(): UseMutationResult<
    void,
    unknown,
    { practitionerId: string }
  >;

  listImportsForPractitioner(
    practitionerId: string
  ): UseQueryResult<BackendApiAutoImportConfigList>;

  createImportForPractitioner(): UseMutationResult<
    void,
    unknown,
    BackendApiCreateImportReq
  >;

  deleteImportForPractitioner(): UseMutationResult<
    void,
    unknown,
    BackendApiAutoImportDeleteReq
  >;

  toggleImportForPractitioner(): UseMutationResult<
    void,
    unknown,
    BackendApiToggleImportReq
  >;

  uploadReportsForPractitionerAndParticipant(): UseMutationResult<
    void,
    unknown,
    BackendApiUploadReportsReq
  >;

  listParticipantReports(): UseQueryResult<
    { name: string; report_id: string }[]
  >;
  loadParticipantReport(
    reportId: string,
    practitionerIdOverride?: string
  ): Promise<[name: string, reports: readonly SurveyReport[]]>;

  loadReportForParticipant(
    participantName: string,
    practitionerId: string
  ): Promise<[name: string, reports: readonly SurveyReport[]]>;

  loadCorrectionsForParticipant(
    participantName: string,
    practitionerIdOverride?: string
  ): UseQueryResult<readonly BackendApiParticipantCorrection[]>;

  loadCorrectionsForParticipantManual(
    participantName: string,
    practitionerId: string
  ): Promise<readonly BackendApiParticipantCorrection[]>;

  updateCorrectionsForParticipant(): UseMutationResult<
    void,
    unknown,
    BackendApiParticipantCorrectionUpdateReq
  >;
}

export function useBackendApi(): BackendApi {
  const { getAccessTokenSilently, user } = useAuth0();
  const queryClient = useQueryClient();

  return useMemo<BackendApi>(
    () => ({
      triggerImport: () => {
        return useMutation({
          mutationFn: async () => {
            const accessToken = await getAccessTokenSilently();
            await fetch(`${API_URL}api/trigger-import`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: accessToken,
              },
            });
          },
        });
      },
      createPractitioner: () => {
        return useMutation({
          mutationFn: async (request: BackendApiCreatePractitonerReq) => {
            const accessToken = await getAccessTokenSilently();
            return fetch(`${API_URL}api/users`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: accessToken,
              },
              body: JSON.stringify(request),
            }).then((res) => res.json());
          },
        });
      },
      listPractitioners: () => {
        return useQuery({
          queryKey: ["practitioners-list"],
          queryFn: async () => {
            const accessToken = await getAccessTokenSilently();
            return fetch(`${API_URL}api/users`, {
              headers: { Authorization: accessToken },
              credentials: "include",
              mode: "cors",
            }).then((res) => res.json());
          },
        });
      },
      resendPasswordEmail: () => {
        return useMutation({
          mutationFn: async (req) => {
            const accessToken = await getAccessTokenSilently();
            await fetch(
              `${API_URL}api/users/${req.practitionerId}/resend-signup-email`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: accessToken,
                },
              }
            );
          },
        });
      },
      listImportsForPractitioner: (practitionerId) => {
        return useQuery({
          queryKey: ["practitioner-imports", { practitionerId }],
          queryFn: async () => {
            const accessToken = await getAccessTokenSilently();
            const res = await fetch(
              `${API_URL}api/users/${practitionerId}/auto-imports`,
              {
                headers: { Authorization: accessToken },
                credentials: "include",
                mode: "cors",
              }
            );

            return await res.json();
          },
        });
      },
      createImportForPractitioner: () => {
        return useMutation({
          mutationFn: async (request: BackendApiCreateImportReq) => {
            const accessToken = await getAccessTokenSilently();
            const res = await fetch(
              `${API_URL}api/users/${request.user_id}/auto-imports`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: accessToken,
                },
                body: JSON.stringify(request),
              }
            );
            return await res.json();
          },
          onSuccess(_, { user_id }) {
            queryClient.invalidateQueries({
              queryKey: ["practitioner-imports", { practitionerId: user_id }],
            });
          },
        });
      },
      deleteImportForPractitioner() {
        return useMutation({
          mutationFn: async (request: BackendApiAutoImportDeleteReq) => {
            const accessToken = await getAccessTokenSilently();
            await fetch(
              `${API_URL}api/users/${request.user_id}/auto-imports/${request.participant_name}`,
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: accessToken,
                },
              }
            );
          },
          onSuccess(_, { user_id }) {
            queryClient.invalidateQueries({
              queryKey: ["practitioner-imports", { practitionerId: user_id }],
            });
          },
        });
      },
      toggleImportForPractitioner: () => {
        return useMutation({
          mutationFn: async (request: BackendApiToggleImportReq) => {
            const accessToken = await getAccessTokenSilently();
            await fetch(
              `${API_URL}api/users/${request.practitioner_id}/auto-imports/${request.participant_name}/toggle`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: accessToken,
                },
                body: JSON.stringify({ enabled: request.enabled }),
              }
            );
          },
          onSuccess(_, { practitioner_id }) {
            queryClient.invalidateQueries({
              queryKey: [
                "practitioner-imports",
                { practitionerId: practitioner_id },
              ],
            });
          },
        });
      },
      uploadReportsForPractitionerAndParticipant: () => {
        return useMutation({
          mutationFn: async (request: BackendApiUploadReportsReq) => {
            const accessToken = await getAccessTokenSilently();

            return fetch(
              `${API_URL}api/users/${request.practitionerId}/reports/${request.participantName}/upload-export`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: accessToken,
                },
                body: JSON.stringify(request.reports),
              }
            ).then((res) => res.json());
          },
        });
      },
      listParticipantReports: () => {
        return useQuery({
          queryKey: ["reports-list"],
          queryFn: async () => {
            const accessToken = await getAccessTokenSilently();
            return fetch(`${API_URL}api/users/${user?.sub}/reports`, {
              headers: { Authorization: accessToken },
              credentials: "include",
              mode: "cors",
            }).then((res) => res.json());
          },
        });
      },
      loadReportForParticipant: async (
        participantName: string,
        practitionerId: string
      ) => {
        const accessToken = await getAccessTokenSilently();
        const practitionerReports: Array<{ name: string; report_id: string }> =
          await fetch(`${API_URL}api/users/${practitionerId}/reports`, {
            headers: { Authorization: accessToken },
            credentials: "include",
            mode: "cors",
          }).then((res) => res.json());

        const reportId = practitionerReports.find(
          (r) => r.name === participantName
        )!.report_id;

        const reportsUrl: { url: string } = await fetch(
          `${API_URL}api/users/${practitionerId}/reports/${participantName}_${reportId}`,
          {
            headers: { Authorization: accessToken },
            credentials: "include",
            mode: "cors",
          }
        ).then((res) => res.json());

        const reports: readonly SurveyReport[] = await fetch(
          reportsUrl.url
        ).then((res) => res.json());

        const name = reportId.split("_")[0];

        return [name, reports];
      },
      loadParticipantReport: async (
        reportId: string,
        practitionerIdOverride?: string
      ): Promise<[name: string, reports: readonly SurveyReport[]]> => {
        const accessToken = await getAccessTokenSilently();
        const practitionerId = practitionerIdOverride ?? user?.sub;
        const reportsUrl: { url: string } = await fetch(
          `${API_URL}api/users/${practitionerId}/reports/${reportId}`,
          {
            headers: { Authorization: accessToken },
            credentials: "include",
            mode: "cors",
          }
        ).then((res) => res.json());

        const reports: readonly SurveyReport[] = await fetch(
          reportsUrl.url
        ).then((res) => res.json());

        const name = reportId.split("_")[0];

        return [name, reports];
      },
      loadCorrectionsForParticipant: (
        participantName: string,
        practitionerIdOverride?: string
      ): UseQueryResult<readonly BackendApiParticipantCorrection[]> => {
        const userId = practitionerIdOverride ?? user?.sub;

        return useQuery({
          queryKey: ["corrections", { userId, participantName }],
          queryFn: async () => {
            const accessToken = await getAccessTokenSilently();
            const correctionsUrl: { url: string } = await fetch(
              `${API_URL}api/users/${userId}/reports/${participantName}/corrections`,
              {
                headers: { Authorization: accessToken },
                credentials: "include",
                mode: "cors",
              }
            ).then((res) => res.json());

            try {
              const corrections: readonly BackendApiParticipantCorrection[] =
                await fetch(correctionsUrl.url).then((res) => res.json());

              return corrections;
            } catch (e) {
              return [];
            }
          },
        });
      },
      async loadCorrectionsForParticipantManual(
        participantName,
        practitionerId
      ) {
        const accessToken = await getAccessTokenSilently();
        const correctionsUrl: { url: string } = await fetch(
          `${API_URL}api/users/${practitionerId}/reports/${participantName}/corrections`,
          {
            headers: { Authorization: accessToken },
            credentials: "include",
            mode: "cors",
          }
        ).then((res) => res.json());

        try {
          const corrections: readonly BackendApiParticipantCorrection[] =
            await fetch(correctionsUrl.url).then((res) => res.json());

          return corrections;
        } catch (e) {
          return [];
        }
      },
      updateCorrectionsForParticipant: () => {
        return useMutation({
          mutationFn: async (
            request: BackendApiParticipantCorrectionUpdateReq
          ) => {
            const accessToken = await getAccessTokenSilently();
            fetch(
              `${API_URL}api/users/${request.practitionerId}/reports/${request.participantName}/corrections`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: accessToken,
                },
                body: JSON.stringify(request.corrections),
              }
            );
          },
        });
      },
    }),
    [getAccessTokenSilently, user]
  );
}
