import { Card, Col, Flex, Row, Statistic, Typography } from "antd";

type Props = {
  title: string;
  value: number | string;
};

export default function NumberHighlightCard(props: Props) {
  return (
    <Card size="small" bordered style={{ width: "100%" }}>
      <Statistic value={props.value} title={props.title} precision={2} />
    </Card>
  );
}
