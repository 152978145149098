export type FunctionOfBehaviour = (typeof FUNCTION_OF_BEHAVIOUR)[number];

export const FUNCTION_OF_BEHAVIOUR = [
  "Obtain an item",
  "Obtain an activity",
  "Obtain a person",
  "Obtain attention",
  "Obtain sensory input",
  "Escape an item",
  "Escape an activity",
  "Escape a person",
  "Escape attention",
  "Escape sensory input",
  "Escape Demand/Request",
] as const;
