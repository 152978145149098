import { useCallback } from "react";
import { Button, Flex, Table, Typography } from "antd";
import { BackendApiAutoImportConfig, useBackendApi } from "../../api/backend";
import { ColumnsType } from "antd/es/table";

export const PractitonerImportsTable = (props: {
  practitionerId?: string;
  onOpen: (participantName: string) => void;
  onTest: (participantName: string) => void;
  onDownload: (formId: string) => void;
  onToggle: (participantName: string, enabled: boolean) => void;
  onCorrections: (participantName: string) => void;
  onClose: () => void;
  onCreate: () => void;
}) => {
  const {
    listImportsForPractitioner,
    deleteImportForPractitioner,
    resendPasswordEmail,
  } = useBackendApi();

  const deleteMutation = deleteImportForPractitioner();
  const resendPasswordEmailMutation = resendPasswordEmail();

  const onDelete = useCallback(
    (participantName: string) => {
      if (!props.practitionerId) {
        return;
      }

      deleteMutation.mutateAsync({
        user_id: props.practitionerId,
        participant_name: participantName,
      });
    },
    [deleteMutation, props.practitionerId]
  );

  const columns: ColumnsType<BackendApiAutoImportConfig> = [
    {
      dataIndex: "participant_name",
      key: "participant_name",
      title: "Participant Name",
    },
    {
      dataIndex: "form_id",
      key: "form_id",
      title: "Form ID",
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Button.Group>
            <Button onClick={() => props.onOpen(record.participant_name)}>
              Open
            </Button>
            <Button
              onClick={() =>
                props.onToggle(
                  record.participant_name,
                  record.disabled ?? false
                )
              }
            >
              {record.disabled ? "Enable" : "Disable"}
            </Button>
            <Button onClick={() => props.onTest(record.participant_name)}>
              Test
            </Button>
            <Button onClick={() => props.onDownload(record.form_id)}>
              Download
            </Button>
            <Button
              onClick={() => props.onCorrections(record.participant_name)}
            >
              Corrections
            </Button>
            <Button onClick={() => onDelete(record.participant_name)}>
              Delete
            </Button>
          </Button.Group>
        );
      },
    },
  ];
  const req = listImportsForPractitioner(props.practitionerId ?? "");

  return (
    <Table
      dataSource={req.data}
      columns={columns}
      loading={req.status !== "success"}
      scroll={{ x: true }}
      bordered
      title={() => (
        <Flex justify="space-between" align="middle">
          <Typography.Text strong>Survey Imports</Typography.Text>
          <Button.Group>
            <Button onClick={props.onCreate}>Add Import</Button>
            <Button
              onClick={() =>
                resendPasswordEmailMutation.mutate({
                  practitionerId: props.practitionerId ?? "",
                })
              }
            >
              Send Password Email
            </Button>
          </Button.Group>
        </Flex>
      )}
    />
  );
};
